// IMPORT
import React, { Fragment } from 'react';

// REDUX
// Hooks
import { useAppSelector } from 'app/hooks';
// Selectors
import { selectUser } from 'features/globalState';

type RoleHideProps = {
    children: JSX.Element | JSX.Element[] | null,
    roles: string,
};

function RoleHide(props: RoleHideProps) {
    const {children, roles} = props;
    const rolesArray = roles.split('');

    const user = useAppSelector(selectUser);
    const loggedUserRole = user ? user.role : 'u';
    
    if (rolesArray.includes(loggedUserRole)) {
        return null;
    }

    return (
        <Fragment>{children}</Fragment>
    );
}

RoleHide.defaultProps = {
    roles: 'u',
};

export default RoleHide;
