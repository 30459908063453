// IMPORT
import React, { useState, Fragment } from 'react';

// REDUX
// Hooks
import { useAppDispatch } from '../../app/hooks';
// Actions
import { setTabWithId, setLoading } from '../../features/globalState';

// Material-UI
import { Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { Container, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import TabHead from 'components/reusable/TabHead';
import CustomerInfo from './CustomerInfo';
import CustomerEdit from 'components/reusable/EditBox/EditBox';
import customerFields from './customersFields.json';

// Hooks
import useData from '../hooks/useData';
import useFilteredData from '../hooks/useFilteredData';
import useDelayedToggle from 'components/hooks/useDelayedToggle';

// Types
import type { Customer } from '../types';

const customersStyles = makeStyles((theme) => createStyles({
    root: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        height: '100%',
        overflow: 'auto',
    },
    searchElements: {
        margin: '0 .5rem',
    },
    listRoot: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    listItems: {
        paddingLeft: theme.spacing(3),
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
}));

function Customers(props: {className: string}) {
    const classes = customersStyles();
    const dispatch = useAppDispatch();

    const [customerInfo, setCustomerInfo] = useState('');
    const [customerEdit, setCustomerEdit] = useState('');
    const [searchField, setSearchField] = useState('');
    
    const filter = ({name}: Customer, search: string) => name.toLowerCase()
        .includes(search.toLowerCase());
        
    
    const [setLoadingTrue, setLoadingFalse] = useDelayedToggle((bool) => dispatch(setLoading(bool)), 100);

    const [data, fetchData] = useData<Customer[]>({url: '/api/customers', initialData: [],
        onFetching: setLoadingTrue,
        onFetched: setLoadingFalse,
    });
    const filteredData = useFilteredData<Customer>({data, search: searchField, filter});

    // useEffect(() => fetchData(), [searchField]);

    const jsxData = filteredData.map((v, i, a) => {
        return (
            <Fragment key={v._id}>
                <ListItem className={classes.listItems}
                    onClick={() => setCustomerInfo(v._id)}>
                    <ListItemText
                        primary={v.name}
                        secondary={`${v.address.address}, ` +
                        `${v.address.postCode} ${v.address.town}, ` +
                        v.address.province}
                    />
                </ListItem>
                {i === (a.length - 1) ? null : <Divider />}
            </Fragment>
        );
    });

    const list = (
        <Paper className={classes.listRoot}>
            <List>
                {jsxData}
            </List>
        </Paper>
    );

    const customersView = (
        <Fragment>
            <TabHead name="Clienti"
                searchbarState={[searchField, setSearchField]}
                buttonText="Nuovo Cliente"
                onButtonClick={() => setCustomerEdit('--new')}
            />
            {filteredData.length > 0 ? list : null}
        </Fragment>
    );

    return (
        <div className={props.className} style={{height: 'calc(100% - 64px)'}}>
            <Container maxWidth="lg" disableGutters className={classes.root}>
                {customersView}
                <CustomerInfo open={Boolean(customerInfo)}
                    id={customerInfo}
                    edit={(id) => {
                        setCustomerInfo('');
                        setCustomerEdit(id);
                    }}
                    onClose={() => {
                        setCustomerInfo('');
                        fetchData();
                    }}
                    newOrder={(id) => {
                        setCustomerInfo('');
                        dispatch(setTabWithId(['orderEdit', '--new*' + id]));
                    }}/>
                <CustomerEdit id={customerEdit}
                    open={Boolean(customerEdit)}
                    title="Cliente" maxWidth="md"
                    template={customerFields}
                    getUrl="/api/customers/:id?flatten"
                    postUrl="/api/customers/:action/:id"
                    onClose={() => {
                        setCustomerEdit('');
                        fetchData();
                    }}
                    onCancel={(id) => {
                        setCustomerEdit('');
                        // To avoid showing "Customer Info"
                        // when action "adding new customer" is canceled
                        if (id !== '--new') {
                            setCustomerInfo(id);
                        }
                        fetchData();
                    }}/>
            </Container>
        </div>
    );
}

Customers.defaultProps = {
    className: null,
};

export default Customers;
