import React from 'react';

// Material-UI
import { Grid, Typography, TextField, Button, InputAdornment } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Material Icons
import SearchIcon from '@material-ui/icons/Search';

// Types
import type { Theme } from '@material-ui/core';

const tabHeaderStyles = makeStyles<Theme, TabHeadProps>((theme) => createStyles({
        searchContainer: {
            marginTop: (p) => p.reduceMarginTop ?
                theme.spacing(.5) : theme.spacing(2),
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        searchElements: {
            margin: '.2rem .3rem',
        },
        title: {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.45rem',
            },
            color: theme.palette.text.primary,
        },
        button: {
            '@media (max-width: 710px)': {
                display: 'none',
            },
        },
        notVisibleBtn: {
            width: theme.spacing(17),
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
}));

type TabHeadProps = {
    name?: string,
    searchbarState: [string, React.Dispatch<React.SetStateAction<string>>],
    reduceMarginTop?: boolean,
    buttonText?: string,
    onButtonClick?: () => void,
    buttonVisibility?: boolean,
};

function TabHead(props: TabHeadProps) {
    const classes = tabHeaderStyles(props);

    const [searchField, setSearchField] = props.searchbarState;
    

    return <Grid className={classes.searchContainer} alignItems="center"
        container direction="row" justifyContent="space-between" wrap="nowrap">
        <Typography
            variant="h6"
            className={classes.searchElements+' '+classes.title}>
            {props.name}
        </Typography>
        <TextField variant="outlined" size="small" color="primary"
            className={classes.searchElements}
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
        }}/>
        {props.buttonVisibility ? (
            <Button
                variant="contained"
                color="primary"
                onClick={() => props.onButtonClick ? props.onButtonClick() : null}
                className={classes.searchElements + ' ' + classes.button}>
                    {props.buttonText}
            </Button>
        ) : (
            <div className={classes.notVisibleBtn}></div>
        )}
    </Grid>;
}

TabHead.defaultProps = {
    name: 'Tab',
    reduceMarginTop: false,
    buttonText: 'Nuovo',
    buttonVisibility: true,
};

export default TabHead;
