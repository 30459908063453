import React from 'react';

// Material-UI
import { Dialog as MDialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogContentText, DialogTitle, Button } from '@material-ui/core';

type DialogProps = {
    open: boolean,
    title: string,
    children: string | string[],
    closeBtnText?: string,
    onClose: () => void,
    cancelBtnText?: string,
    cancelBtnVisibility: boolean,
    onCancel?: () => void,
};

function Dialog(props: DialogProps) {
    const {open, title, children, closeBtnText, onClose,
        cancelBtnText, cancelBtnVisibility, onCancel} = props;

    const cancelBtn = (
        <Button onClick={() => onCancel && onCancel()} color="primary">
            {cancelBtnText}
        </Button>
    );

    return (
        <MDialog
            open={open}
            // onClick={(e) => e.stopPropagation()}
            onClose={onClose}>
            <DialogTitle>{title}</DialogTitle> {/* Creare un nuovo ordine è semplice! */}
            <DialogContent>
                <DialogContentText>
                    {/* Vai nella sezione Clienti, seleziona un cliente e clicca su
                    NUOVO ORDINE! */}
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelBtnVisibility ? cancelBtn : null}
                <Button onClick={onClose} color="primary"
                    autoFocus>
                    {closeBtnText}
                </Button>
            </DialogActions>
        </MDialog>
    );
}

Dialog.defaultProps = {
    closeBtnText: 'OK',
    cancelBtnText: 'Annulla',
    cancelBtnVisibility: true,
}

export default Dialog;
