import { name as nameRegex, email as emailRegex, password as passwRegex } from 'validation';
import type { InputDataType } from 'components/reusable/EditBox/EditBoxTypes';

const userFields : InputDataType = {
    firstName: {
        name: 'Nome',
        required: true,
        validate: (newValue: string) => nameRegex.test(newValue),
    },
    lastName: {
        name: 'Cognome',
        required: true,
        validate: (newValue: string) => nameRegex.test(newValue),
    },
    email: {
        name: 'Email',
        required: true,
        validate: (newValue: string) => emailRegex.test(newValue),
    },
    role: {
        name: 'Ruolo',
        required: true,
        type: 'select',
        defaultValue: 'u',
        options: [
            {
                key: 'u',
                value: 'Utente semplice',
            },
            {
                key: 'a',
                value: 'Amministratore',
            },
            {
                key: 's',
                value: 'The best',
                disabled: true,
            }
        ]
    },
    resetPassword: {
        name: 'Reset Password',
        required: true,
        type: 'select',
        defaultValue: '<false>',
        options: [
            {
                key: '<true>',
                value: 'Sì',
            },
            {
                key: '<false>',
                value: 'No',
            },
        ],
    },
    prevPass: {
        name: 'Vecchia Password',
        type: 'password',
        validate: (newValue: string) => passwRegex.test(newValue),
    },
    newPass: {
        name: 'Nuova Password',
        type: 'password',
        validate: (newValue: string) => passwRegex.test(newValue),
    },
    repNewPass: {
        name: 'Ripeti Nuova Password',
        type: 'password',
        validate: (newValue: string) => passwRegex.test(newValue),
    },

};

export default userFields;


// export default [
//     {
//         name: 'Nome',
//         key: 'firstName',
//         obl: true,
//         validate: nameRegex,
//     },
//     {
//         name: 'Cognome',
//         key: 'lastName',
//         obl: true,
//         validate: nameRegex,
//     },
//     {
//         name: 'Email',
//         key: 'email',
//         obl: true,
//         validate: emailRegex,
//     },
//     {
//         name: 'Ruolo',
//         options: [
//             {
//                 name: 'Utente semplice',
//                 key: 'u',
//             },
//             {
//                 name: 'Amministratore',
//                 key: 'a',
//             },
//             {
//                 name: 'The best',
//                 key: 's',
//                 disabled: true,
//             },
//         ],
//         key: 'role',
//         type: 'select',
//         default: 'u',
//         obl: true,
//     },
//     {
//         name: 'Reset Password',
//         options: [
//             {
//                 name: 'Sì',
//                 key: '<true>',
//             },
//             {
//                 name: 'No',
//                 key: '<false>',
//             },
//         ],
//         key: 'resetPassword',
//         type: 'select',
//         default: '<false>',
//         obl: true,
//     },
//     {
//         name: 'Vecchia Password',
//         key: 'prevPass',
//         obl: false,
//         type: 'password',
//         validate: passwRegex,
//     },
//     {
//         name: 'Nuova Password',
//         key: 'newPass',
//         obl: false,
//         type: 'password',
//         validate: passwRegex,
//     },
//     {
//         name: 'Ripeti Nuova Password',
//         key: 'repNewPass',
//         obl: false,
//         type: 'password',
//         validate: passwRegex,
//     },
// ];
