// IMPORT
import React, { Fragment, useState } from 'react';

// REDUX
// Hooks
import { useAppDispatch } from '../../app/hooks';
// Actions
import { setTabWithId, setLoading } from '../../features/globalState';

// Material-UI
import { Container, Paper } from '@material-ui/core';
import { TableHead as Th, TableBody as Tb } from '@material-ui/core';
import { TableRow as Tr, TableCell as Td } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import TabHead from 'components/reusable/TabHead';
import Table from 'components/reusable/Table';
import HowToNewOrderDialog from 'components/reusable/Dialog';
import OrderInfo from './OrderInfo';

// Hooks
import useData from 'components/hooks/useData';
import useFilteredData from 'components/hooks/useFilteredData';
import useDelayedToggle from 'components/hooks/useDelayedToggle';

// Utils
import { ddmmyyyy, hhmm } from '../utils';

// Types
import type { Order } from 'components/types';

const ordersStyles = makeStyles((theme) => createStyles({
        root: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            height: '100%',
            overflow: 'auto',
        },
        listRoot: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
        cell: {
            fontSize: '1rem',
            padding: theme.spacing(1.5),
        },
        addressCell: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        agentCell: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        tableRowItems: {
            '&:hover': {
                backgroundColor: theme.palette.action.focus,
            },
            textTransform: 'uppercase',
            cursor: 'pointer',
        },
        stillNoOrders: {
            // color: theme.palette.text.secondary,
            margin: '2rem 0 0 3rem',
            padding: '1rem',
            display: 'inline-block',
            // display: 'none',
        }
}));


function Orders(props: {className?: string} | undefined) {
    const classes = ordersStyles();
    const {cell, addressCell, agentCell, tableRowItems} = classes;

    const dispatch = useAppDispatch();
    const edit = (id: string) => dispatch(setTabWithId(['orderEdit', id]));
    const [orderInfo, setOrderInfo] = useState('');
    const [searchField, setSearchField] = useState('');
    const [howToNewOrd, setHowToNewOrd] = useState(false);

    const filter = ({agent, customer, n} : Order, search: string) => {
        if (agent === null) agent = {_id: '', name: {first: '', last: ''}};
        search = search.toLowerCase();
        const {first, last} = agent.name;
        return String(n).includes(search) ||
        customer.name.toLowerCase().includes(search) ||
        (first.toLowerCase() + last.toLowerCase()).includes(search);
    }
    
    const [setLoadingTrue, setLoadingFalse] = useDelayedToggle((bool) => dispatch(setLoading(bool)), 100);

    const [data, fetchData, dataLoaded] = useData<Order[]>({url: '/api/orders', initialData: [],
        onFetching: setLoadingTrue,
        onFetched: setLoadingFalse,
    });
    const filteredData = useFilteredData<Order>({data, search: searchField, filter})

    // useEffect(() => fetchData(), [searchField]);
    
    const jsxData = filteredData.map((v) => {
        const date = new Date(v.creationDate);
        if (v.agent === null) {
            v.agent = {
                _id: 'id_utente_inesistente',
                name: {
                    first: 'Utente',
                    last: 'Inesistente',
                }
            };
        }
        return (
            <Tr className={tableRowItems} key={v._id}
                onClick={() => setOrderInfo(v._id)}>
                <Td className={cell}>
                    {v.n}
                </Td>
                <Td className={cell}>
                    {v.customer.name}
                </Td>
                <Td className={cell + ' ' + addressCell}>
                    {`${v.customer.address.address}, ` +
                `${v.customer.address.postCode} ${v.customer.address.town}, ` +
                    v.customer.address.province}
                </Td>
                <Td className={cell + ' ' + agentCell}>
                    {v.agent.name.first + ' ' + v.agent.name.last}
                </Td>
                <Td className={cell}>
                    {hhmm(date, false)}<br/>{ddmmyyyy(date, false)}
                </Td>
            </Tr>
        );
    });

    const table = (
        <Table>
            <Th>
                <Tr>
                    <Td className={cell}>Num</Td>
                    <Td className={cell}>Cliente</Td>
                    <Td className={cell + ' ' + addressCell}>
                    Indirizzo
                    </Td>
                    <Td className={cell + ' ' + agentCell}>
                    Agente
                    </Td>
                    <Td className={cell}>Data/Ora</Td>
                </Tr>
            </Th>
            <Tb>
                {jsxData}
            </Tb>
        </Table>
    );

    const stillNoOrders = (
        <Paper className={classes.stillNoOrders}>
            Per il momento, non hai spedito alcun ordine! <br />
            Vai nella sezione Clienti, seleziona un cliente e clicca su NUOVO ORDINE!
        </Paper>
    );

    const ordersView = (
        <Fragment>
            <TabHead name="Ordini"
                searchbarState={[searchField, setSearchField]}
                buttonText="Nuovo Ordine"
                onButtonClick={() => setHowToNewOrd(true)}
            />

            {data.length > 0 ? table : (dataLoaded ? stillNoOrders : null)}

            <HowToNewOrderDialog
                open={howToNewOrd}
                title="Creare un nuovo ordine è semplice!"
                cancelBtnVisibility={false}
                onClose={() => setHowToNewOrd(false)}
            >
                Vai nella sezione Clienti, seleziona un cliente e clicca su
                NUOVO ORDINE!
            </HowToNewOrderDialog>

            <OrderInfo
                open={Boolean(orderInfo)}
                id={orderInfo}
                edit={(id) => {
                    setOrderInfo('');
                    edit(id);
                }}
                onClose={() => {
                    setOrderInfo('');
                    fetchData();
                }}/>

        </Fragment>
    );
    return (
        <div className={props && props.className} style={{height: 'calc(100% - 64px)'}}>
            <Container maxWidth="lg" disableGutters className={classes.root}>
                {ordersView}
            </Container>
        </div>
    );
}

export default Orders;
