import { createStyles, makeStyles } from "@material-ui/core";

const useOrderEditStyles = makeStyles((theme) => {
    const darkMode = theme.palette.type === 'dark';
    const totalBgs = darkMode ? ['#3a4d8fd1', '#2c3968d1'] : ['#ebfbff', '#d6ebfa'];
    
    return createStyles({
        root: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                padding: `0 ${theme.spacing(3)}px`,
            },
            display: 'flex',
            flexDirection: 'column',
        },
        searchContainer: {
            marginTop: theme.spacing(.5),
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        searchElements: {
            margin: '.2rem .3rem',
        },
        title: {
            color: theme.palette.text.primary,
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '1.45rem',
            },
        },
        customerName: {
            color: theme.palette.text.primary,
            alignSelf: 'center',
            fontSize: '1.4rem',
            fontWeight: 'bolder',
            borderRadius: '.5rem',
            background: darkMode ? '#3a4d8fd1' : '#ffffffd1',
            width: '100%',
            textAlign: 'center',
            textTransform: 'uppercase',
            [theme.breakpoints.up('sm')]: {
                padding: '0 1rem',
            },
        },
        actionsBox: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexShrink: 20,
        },
        cell: {
            fontSize: '1rem',
            textAlign: 'center',
            padding: `0 ${theme.spacing(2)}px`,
        },
        descriptionCell: {
            textAlign: 'left',
        },
        smNone: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        xsNone: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        total: {
            position: 'fixed',
            bottom: 0,
            right: 0,
            padding: '12px 24px',
            borderRadius: '.5rem .5rem 0 0',
            fontSize: '1rem',
            fontWeight: 'bold',
            transform: 'translateX(-50%)',
            // background: '#ebfbff',
            // background: 'radial-gradient(circle, #ebfbff 0%, #d6ebfa 100%)',
            background: `radial-gradient(circle, ${totalBgs[0]} 0%, ${totalBgs[1]} 100%)`,
            [theme.breakpoints.down('xs')]: {
                padding: '6px 12px',
            },
            color: theme.palette.text.primary,
        },
        tableRow: {
            height: theme.spacing(8),
            transition: 'ease all 0.2s',
        },
        tableRowNotEmpty: {
            background: darkMode ? '#2f7422' : '#b9f7ad',
        },
    });
});

export default useOrderEditStyles;
