// IMPORT
import React from 'react';

// Material-UI
import { AppBar, Tabs, Tab, IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Material Icons
import SettingsIcon from '@material-ui/icons/Settings';

// Components
import RoleHide from './RoleHide';


const useStyles = makeStyles((theme) => createStyles({
    root: {
        flexGrow: 1,
        width: `calc(100% - ${theme.spacing(2*2)}px)`,
        margin: theme.spacing(2),
        marginTop: theme.spacing(1),
        // backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
}));

type ScrollableTabsProps = {
    tabIndex: number,
    namesList: {
        _id: string,
        name: string,
    }[],
    setTab: (tabIndex: number) => void,
    children?: React.ReactNode,
    bgColor?: string,
    txtColor?: string,
    openSettings?: () => void,
};

function ScrollableTabs(props: ScrollableTabsProps) {
    const classes = useStyles(props);
    const {tabIndex, namesList, setTab, children, openSettings} = props;
    const hasSettings = openSettings !== undefined;

    const handleChange = (e: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const tabs = namesList.map((obj) => (
        <Tab label={obj.name} key={obj._id} />
    ));

    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit"
                style={props.bgColor ? {background: props.bgColor} : {}}
                elevation={1} className={classes.appBar}>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor={props.txtColor !== undefined ? "inherit" : "primary"}
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{flexGrow: 1, color: props.txtColor}}
                >
                    {tabs}
                </Tabs>
                <RoleHide>
                    {hasSettings ? <div style={{width: 55}}>
                        <IconButton onClick={openSettings}>
                            <SettingsIcon />
                        </IconButton>
                    </div> : null}
                </RoleHide>
            </AppBar>
            {children}
        </div>
    );
}

export default ScrollableTabs;
