import { createSlice } from "@reduxjs/toolkit";

// Types
import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";

import type { InputDataType, DataType, Option, Edit,
    AddRemData, AddRemSchema, AddRemAdd, AddRemEdit, AddRemDelete, Field } from "./EditBoxTypes";


// INTERNAL HELPERS
// These helpers are meant to be used inside the reducers of createSlice().

/**
 * Get Key
 * @returns A function that will be passed as second argument to validate function in the template.
 * See validate function here and in a template for more information.
 */
const getKey = (state: WritableDraft<DataType>) => (key: string) => state[key] ? state[key].value : undefined;

const setDisabled = (value: boolean, state: WritableDraft<DataType>) => {
    for (const key in state) {
        state[key].disabled = value;
    }
}
/**
 * VALIDATION PROCESS
 * This function will validate the field with the specified key.
 * @param key
 * @param state The object with the field to be validated.
 * Expecting state to be of the declared type.
 * @param noValidateAll Optional parameter added in order to avoid recursion when validating all data
 */
const validate = (key: string, state: WritableDraft<DataType>, noValidateAll?: boolean) => {
    const { value, preValidate, validate } = state[key];

    if (key === '_id') return;

    // PRE-VALIDATION:
    // Note that Prevalidation DOES NOT change data for the user.
    // It acts just like a middleware before validation.
    const valueForValidation = preValidate ?
        preValidate(value) : value;

    // DATA REQUIRED:
    let { required = false } = state[key];
    if (typeof required === 'function') required = required(getKey(state));

    state[key].error = false;

    if (value === '') {
        if (required)
            state[key].error = true;
        return;
    }

    // VALIDATION
    // Note that only valueForValidation is processed by Prevalidation.
    // If you try to access other data by invoking getKey() ,
    // you will receive the data as it is.
    if (validate) {
        if (validate(valueForValidation, getKey(state))) {
            state[key].error = false;
        } else {
            state[key].error = true;
        }
    }
    
    // VALIDATE-ALL
    // If there's a field with 'validateAll' prop = true
    // Then, every edit to that field will validate again all the other fields.
    // This feature is useful when other fields' validation depends on this field's value.
    // In the future, this might be automatically made by the validation process.
    if (!noValidateAll && state[key].validateAll) {
        validateAll(state);
    }
}

// validateAll: validates all fields
const validateAll = (state: WritableDraft<DataType>) => {
    for (const key in state) {
        validate(key, state, true);

        // For AddRem Fields:
        if (Array.isArray(state[key].value)) {
            state[key].value.forEach((addRemUnit: any) => validateAll(addRemUnit));
        }
    }
}

// getOptionsValidate returns a validate function.
// It will check if user selection is in between the options.
const getOptionsValidate = (options: Option<unknown>[]) =>
    (newValue: unknown) => options.findIndex((option) =>
        option.key === newValue) >= 0;


export const initialData : DataType = {};

const editBoxSlice = createSlice({
    name: 'editbox',
    initialState: initialData,
    reducers: {
        /**
         * PHASE 1: POPULATION
         * This reducer will take the template and prepare it to be used in EditBox.
         * It transforms data from InputDataType --> DataType
         * Note that DataType does NOT have 'defaultValue'. Instead, it DOES have these keys:
         * {value, initialValue, validateAll, error, disabled}
         */
        populate: (state, action: PayloadAction<InputDataType>) => {
            for (const key in action.payload) {

                const { type = 'text', name = '',
                        defaultValue = action.payload[key].type === 'addrem' ? [] : '',
                        required = false,
                        validate, preValidate, validateAll = false,
                        multiline, options } = action.payload[key];

                const field : DataType[keyof DataType] = {
                    type, name, required, validate,
                    preValidate, multiline, options,

                    value: defaultValue,
                    initialValue: defaultValue,
                    validateAll, defaultValue,
                    error: false,
                    disabled: false,
                };
                if (options && validate === undefined) {
                    field.validate = getOptionsValidate(options);
                }
                if (type === 'addrem') field.schema = action.payload[key].schema;
                state[key] = field;
            }
        },
        /**
         * PHASE 2: INITIALIZATION
         * This reducer will take the data from the server and display it to the user.
         * For AddRem Fields, it will create as fields as needed with the fetched data.
         * For other fields, it will just assign fetched data to value and initialValue.
         * 
         * It does have also remoteOptions feature.
         */
        initialise: (state, action: PayloadAction<{[key: string]: any}>) => {
            for (const key in action.payload) {
                const fetchedValue = action.payload[key];

                if (state[key] === undefined) continue;


                if (state[key].type === 'addrem' && state[key].schema) {
                    const fetchedArray = fetchedValue as ({_id: string} & {[key: string]: string})[];
                    const schema = state[key].schema;
                    const stateArray : AddRemData[] = [];
                    for (let i = 0; i < fetchedArray.length; i++) {
                        const fetchedUnit = fetchedArray[i];
                        const stateUnit : AddRemData = {};

                        // Process similar to POPULATE
                        for (const schemaKey in schema) {
                            const { type = 'text', name = '',
                            defaultValue = schema[schemaKey].type === 'addrem' ? [] : '',
                            required = false,
                            validate, preValidate, validateAll = false,
                            multiline, options } = schema[schemaKey];

                            const fetchedKey = fetchedUnit[schemaKey];
                            
                            if (fetchedKey === undefined) continue;
                            
                            const field : Field<any> = {
                                type, name, required, validate,
                                preValidate, multiline, options,
            
                                value: fetchedKey === '' ? defaultValue : fetchedKey,
                                initialValue: fetchedKey === '' ? defaultValue : fetchedKey,
                                validateAll, defaultValue,
                                error: false,
                                disabled: false,
                            };
                            stateUnit[schemaKey] = field;
                        }
                        stateUnit._id = fetchedUnit._id;
                        stateArray.push(stateUnit);
                    }
                    state[key].initialValue = stateArray;
                    state[key].value = stateArray;
                    return;
                }


                state[key].initialValue = fetchedValue;
                state[key].value = fetchedValue;

                // Remote Options for Select Field
                const remoteOptions = action.payload[key + '-remoteOptions'];
                if (remoteOptions) state[key].options = remoteOptions;
                if (remoteOptions && state[key].validate === undefined)
                    state[key].validate = getOptionsValidate(remoteOptions);
            }
        },
        
        /**
         * ACTION: Edit Field
         * This will edit a field's value.
         * DO NOT use it for AddRem Fields.
         */
        editField: (state, action: PayloadAction<Edit<string>>) => {
            const { key, newValue } = action.payload;
            if (state[key] === undefined || state[key].type === 'addrem') return;

            state[key].value = newValue;

            validate(key, state);
        },
        /**
         * ACTION: Add AddRem Field
         * This will add an empty AddRem Field.
         */
        add_AddRem: (state, action: PayloadAction<AddRemAdd>) => {
            const { key } = action.payload;
            if (state[key] === undefined || state[key].type !== 'addrem' || state[key].schema === undefined) return;
            const stateArray = state[key].value as WritableDraft<AddRemData>[];
            const stateUnit : AddRemData = {};
            const schema = state[key].schema;
            for (const schemaKey in schema) {
                const { type = 'text', name, defaultValue,
                    required, validate, preValidate, validateAll,
                    multiline, options } = schema[schemaKey];
                
                const tempId = () => 'temp' + Math.floor(Math.random() * Date.now());
                
                const field : Field<any> = {
                    type, name, required, validate,
                    preValidate, multiline, options,

                    value: defaultValue !== undefined ? defaultValue : '',
                    initialValue: defaultValue !== undefined ? defaultValue : '',
                    validateAll, defaultValue,
                    error: false,
                    disabled: false,
                };
                stateUnit._id = tempId();
                stateUnit[schemaKey] = field;
            }
            stateArray.push(stateUnit);
        },
        /**
         * ACTION: Edit AddRem Field
         * This will edit an AddRem field's value.
         */
        edit_AddRem: (state, action: PayloadAction<AddRemEdit<any>>) => {
            const { key, _id, field, newValue } = action.payload;
            if (state[key].type !== 'addrem') return;
            const stateArray = state[key].value as WritableDraft<AddRemData>[];

            const i = stateArray.findIndex((v: AddRemSchema<any>) =>
                v._id === _id);
            if (i < 0) return;

            stateArray[i][field].value = newValue;

            validate(field, stateArray[i]);
            validate(key, state);

        },
        /**
         * ACTION: Delete AddRem Field
         * This will delete an AddRem Field.
         */
        delete_AddRem: (state, action: PayloadAction<AddRemDelete>) => {
            const { key, _id } = action.payload;
            if (state[key].type !== 'addrem') return;
            const stateArray = state[key].value as WritableDraft<AddRemData>[];

            const i = stateArray.findIndex((v: AddRemSchema<any>) =>
                v._id === _id);
            if (i < 0) return;

            stateArray.splice(i, 1);

            validate(key, state);
        },
        
        disableFields: (state) => {
            setDisabled(true, state);
        },
        enableFields: (state) => {
            setDisabled(false, state);
        },

        
        /**
         * PHASE 3: CANCELLATION
         * This reducer will REVERT Initialization Phase.
         */
        clear: (state) => {
            for (const key in state) {
                let _value : any = '';

                // Looking for default value
                if (state[key].defaultValue !== undefined) {
                    _value = state[key].defaultValue;
                }
                if (Array.isArray(state[key].value)) {
                    _value = [];
                }
                state[key].value = _value;
                state[key].initialValue = _value;
                state[key].error = false;
            }
        },
    },
});

export const { populate, initialise, editField,
    add_AddRem, edit_AddRem, delete_AddRem,
    disableFields, enableFields, clear } = editBoxSlice.actions;




// HELPERS

/**
 * ARE THERE EDITS?
 * @param state
 * @returns True if there have been edits.
 */
export const areThereEdits = (state: DataType) => {
    for (const key in state) {
        const { value, initialValue } = state[key];

        // Comparing *strings* or *arrays memory address*.
        // Remember that ImmerJS (inside createSlice of Redux Toolkit)
        // records every edit and keeps the object immutable.
        // If there are no edits in a value of a specific subkey,
        // Memory address of the value of that key is COPIED.
        if (value !== initialValue) return true;

    }
    return false;
};

/**
 * IS ALL VALID?
 * This will check every field of the state.
 * @param state 
 * @returns True if it is all valid.
 */
export const isAllValid = (state: DataType) => {
    for (const key in state) {
        if (state[key].error === true) return false;

        // For AddRem Field:
        if (Array.isArray(state[key].value)) {
            for (let i = 0; i < state[key].value.length; i++) {
                const addRemUnit = state[key].value[i]
                if (!(isAllValid(addRemUnit))) return false;
            }
        }
    }
    return true;
};

/**
 * SANITIZE DATA
 * @param state 
 * @param wantOnlyChanged If true, returns only MODIFIED values.
 * @returns An object with only the values of the fields.
 */
export const sanitizeData = (state: DataType, wantOnlyChanged?: boolean) : {[key: string] : any} => {
    const sanitizedData : {[key: string] : any} = {};
    for (const key in state) {

        // For AddRem Fields: (this function is part of a ricursion!)
        if (key === '_id') {
            // Copying _id if it is not temporary and CONTINUE
            //@ts-ignore
            if (!(state._id.includes && state._id.includes('temp')))
                sanitizedData[key] = state._id;
            continue;
        }


        const { value, initialValue } = state[key];

        // See comment in 'areThereEdits'
        if (wantOnlyChanged && value === initialValue) continue;

        // For AddRem Fields:
        if (Array.isArray(value)) {
            sanitizedData[key] = value.map((obj) => sanitizeData(obj));
            continue;
        }

        if(value === '') {
            // To report the server that a non-required field was deleted:
            sanitizedData[key] = '<undefined>';
            continue;
        }

        sanitizedData[key] = value;
    }
    return sanitizedData;
}


export default editBoxSlice.reducer;