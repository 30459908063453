// IMPORT
import React, { useReducer, useState } from 'react';
import { createSlice } from '@reduxjs/toolkit';

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from '../app/hooks';
// Actions
import { setLoading as changeLoading } from '../features/globalState';
import { enter } from '../features/authActions';
// Selectors
import { selectLoading } from '../features/globalState';

// MATERIAL-UI
import { Container, TextField, makeStyles, Typography } from '@material-ui/core';
import { Box, Button } from '@material-ui/core';

// Components
import EditPassword from './EditPassword';

// Utils
import axios from 'axios';

// To validate email and passw fields
import { email as emailRegex, password as passwRegex } from 'validation';

// Types
import type { PayloadAction } from '@reduxjs/toolkit';

const loginStyle = makeStyles((theme) => ({ // Styles
    root: {
        border: 'solid 1px',
        borderColor: theme.palette.divider,
        borderRadius: '15px',
        margin: '2.75rem auto',
        padding: theme.spacing(2.5),
        width: '25rem',
        height: '22.2rem',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: '21rem',
        },
    },
    secondContainer: {
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    labels: {
        margin: '10px 0',
        width: '100%',
    },
    titleLabel: {
        display: 'block',
        margin: '15px auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        width: '10ch',
        color: theme.palette.text.primary,
    },
}));

const initialState = {
    email: '',
    password: '',
    error: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
    }
});
const {setEmail, setPassword, setError} = loginSlice.actions;

function Login() {
    const appDispatch = useAppDispatch();
    // States
    const [loading, setLoading] = [useAppSelector(selectLoading),
        (loading: boolean) => appDispatch(changeLoading(loading))];
    const [{email, password, error}, dispatch] = useReducer(loginSlice.reducer, initialState);
    const [editPassword, setEditPassword] = useState(false);

    const classes = loginStyle(); // styles

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Showing Linear Loading
        setLoading(true);

        // Validating email and passw fields
        if (email.match(emailRegex) && password.match(passwRegex)) {

            // Beginning login process
            // Perform login request
            axios.post('/api/login', {
                username: email,
                password,
            }).then((res) => {
                if (res.status === 202) {
                    return setEditPassword(true);
                }
                appDispatch(enter());
            })
                .catch((err) => {
                    // const res = err.response;

                    // User or passw incorrect:
                    dispatch(setError(true));
                    setLoading(false);
                });
        } else {
            // User or passw not valid:
            dispatch(setError(true));
            setLoading(false);
        }
    };
    const handleChange = (field: 'email' | 'password',
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { // e = onChange event
        
        dispatch(setError(false));

        if (field === 'email') {
            // Deleting spaces if present
            dispatch(setEmail(e.currentTarget.value.replaceAll(' ', '')));
        } else {
            dispatch(setPassword(e.currentTarget.value));
        }
    };

    const errorLabel = <Typography color="error">
        Email o password non corretti.</Typography>;

    return (
        <Container className={classes.root} maxWidth="xs">
            <form onSubmit={handleSubmit}>
                <Container maxWidth="xs" className={classes.secondContainer}>
                    <Typography variant="h5"
                        className={classes.labels + ' ' + classes.titleLabel}>
                    Accedi
                    </Typography>
                    <TextField
                        className={classes.labels}
                        label="Email"
                        variant="outlined"
                        autoFocus
                        name="email-darisalus"
                        disabled={loading}
                        error={error}
                        onChange={(e) => handleChange('email', e)}
                    />
                    <TextField
                        className={classes.labels}
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        disabled={loading}
                        error={error}
                        onChange={(e) => handleChange('password', e)}
                    />
                    <Box style={{height: '40px'}}>
                        {error ? errorLabel : ''}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{float: 'right'}}
                        disabled={loading || error}>
                    Avanti
                    </Button>
                </Container>
            </form>
            <EditPassword open={editPassword} login={() => {
                setEditPassword(false);
                appDispatch(enter());
            }}/>
        </Container>
    );
}

export default Login;
