// IMPORT
import React from 'react';

// Material-UI
import { Backdrop, Container, Slide, LinearProgress } from '@material-ui/core';
import { Grid, Paper, Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
// Material Icon
import CloseIcon from '@material-ui/icons/Close';

// Utils
import clsx from 'clsx';

// Types
import type { GridJustification } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    container: {
        // height: '70%',
    },
    paper: {
        // minHeight: '413px',
    },
    paperContainer: {
        padding: `0 ${theme.spacing(4)}px 0 ${theme.spacing(4)}px`,
        [theme.breakpoints.up('sm')]: {
            padding: `0 ${theme.spacing(6)}px 0 ${theme.spacing(6)}px`,
        },
    },
    actionsBox: {
        padding: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(6),
            paddingTop: theme.spacing(4),
        },
    },
}));

type InfoBoxProps = {
    open: boolean,
    onClose: () => void,
    onExited?: () => void,
    children: React.ReactChild | React.ReactFragment | React.ReactPortal,
    actions?: React.ReactNode,
    elevation?: number,
    loading?: boolean,
    classes?: {
        container?: string,
        paper?: string,
        paperContainer?: string,
        actionsBox?: string,
    },
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    buttonsAlign?: GridJustification,
};

function InfoBox(props: InfoBoxProps) {
    const classes = styles(props);
    const {container, paper, paperContainer, actionsBox} = classes;

    // 'inh' = 'inherited'
    const inhClasses = props.classes ? props.classes : {};

    const {open, loading, children, actions, elevation,
        maxWidth, buttonsAlign, onClose} = props;


    const actBox = (
        <Grid
            container
            direction="row"
            justifyContent={buttonsAlign}
            alignItems="center"
            className={clsx(actionsBox, inhClasses.actionsBox)}>
            {actions}
        </Grid>
    );

    return (
        <Backdrop className={classes.backdrop} open={open}
            onClick={onClose}>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit
                // onExited={edit ? () => props.setLoading(false) : null}
                onExited={props.onExited}
            >
                <Container className={clsx(container, inhClasses.container)}
                    onClick={(e) => e.stopPropagation()}
                    maxWidth={maxWidth}>
                    <Paper elevation={elevation}
                        className={clsx(paper, inhClasses.paper)}>

                        {loading ? <LinearProgress style={{flexShrink: 0}} /> :
                            <div style={{height: 4, flexShrink: 0}}></div>}
                        
                        <Toolbar style={{flexDirection: 'row-reverse'}}>
                            <IconButton edge="end" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>

                        <Container
                            className={clsx(paperContainer, inhClasses.paperContainer)}
                        >
                            {children}
                        </Container>
                        {actions ? actBox : <div style={{height: 48}}></div>}
                    </Paper>
                </Container>
            </Slide>
        </Backdrop>
    );
}

InfoBox.defaultProps = {
    elevation: 4,
    loading: false,
    children: null,
    actions: null,
    maxWidth: 'sm',
    buttonsAlign: 'space-between',
};

export default InfoBox;
