// IMPORT
import React from 'react';

// Components
import EditBox from './reusable/EditBox/EditBox';

// Utils
import { password as passwRegex } from '../validation';

// Types
import type { InputDataType } from './reusable/EditBox/EditBoxTypes';


const passwordFields : InputDataType = {
    prevPass: {
        name: 'Vecchia Password',
        required: true,
        type: 'password',
        validate: (newValue: string) => passwRegex.test(newValue),
    },
    newPass: {
        name: 'Nuova Password',
        required: true,
        type: 'password',
        validate: (newValue: string, get: (key: string) => string) =>
            passwRegex.test(newValue) && newValue === get('repNewPass'),
        validateAll: true,
    },
    repNewPass: {
        name: 'Ripeti Nuova Password',
        required: true,
        type: 'password',
        validate: (newValue: string, get: (key: string) => string) =>
            passwRegex.test(newValue) && newValue === get('newPass'),
        validateAll: true,
    },
}


function EditPassword({open, login}: {open: boolean, login: () => void}) {
    const op = open ? 'id' : '';
    return <EditBox id={op}
        open={open}
        maxWidth="sm"
        height="50%"
        title="Password"
        disableCancel
        template={passwordFields}
        getUrl="/api/editPassword" postUrl="/api/editPassword"
        onClose={login}/>;
};


export default EditPassword;
