// IMPORT
import { useCallback, useEffect, useRef, useState } from "react";

// Hooks
import { useAppDispatch } from "app/hooks";
// Actions
import { push } from "features/notifications";

// To perform requests
import axios from "axios";

type Response<T> = {
    ok: boolean,
    result: T,
}

type useDataProps<T> = {
    url: string,
    initialData: T | (() => T),
    onFetching?: () => void,
    onFetched?: () => void,
};

function useData<T>({url, initialData, onFetching, onFetched} : useDataProps<T>) : [T, () => void, boolean] {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<T>(initialData);
    const dataLoaded = useRef<boolean>(false);

    const fetchData = useCallback(() => {
        if (url === '') {
            return;
        }
        onFetching && onFetching();
        axios.get<Response<T>>(url)
            .then((res) =>{
                dataLoaded.current = true;
                setData(res.data.result);
            })
            .catch(() => dispatch(push({
                severity: 'error',
                message: 'Errore di comunicazione col server.'
            })))
            .finally(() => onFetched && onFetched())
    }, [url, dispatch]) 

    useEffect(fetchData, [fetchData]);

    return [data, fetchData, dataLoaded.current];
}

export default useData;