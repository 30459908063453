// IMPORT
import React, { useState } from 'react';

// Material-UI
import { Typography, TableBody as Tb } from '@material-ui/core';
import { TableCell as Td, TableRow as Tr } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import InfoBox from 'components/reusable/InfoBox';
import Table from 'components/reusable/Table';

// Hooks
import useData from 'components/hooks/useData';

// Types
import type { Discount } from 'components/types';

const styles = makeStyles((theme) => createStyles({
    boldText: {
        fontWeight: 500,
    },
}));

type DiscountsInfoProps = {
    open: boolean,
    id: string,
    onClose: () => void,
};

function DiscountsInfo(props: DiscountsInfoProps) {
    const classes = styles();
    const {open, id, onClose} = props;

    const [loading, setLoading] = useState(false);

    const url = id === '' ? '' : '/api/products/discounts/' + id;
    const [data] = useData<Discount[]>({url, initialData: [],
        onFetching: () => setLoading(true),
        onFetched: () => setLoading(false),
    });


    const jsxData = data.map((d) => (
        <Tr key={d._id}>
            <Td>{d.from}-{d.to}pz</Td>
            <Td>{d.percent}%</Td>
        </Tr>
    ));
    const title = data.length > 0 ? 'Sconti Disponibili' :
        'Nessuno sconto disponibile';

    return (
        <InfoBox open={open} actions={null} onClose={onClose} loading={loading}>
            <Table elevation={4} disableMargin>
                <Tb>
                    <Tr>
                        <Td colSpan={2} className={classes.boldText}>
                            <Typography variant="h6"
                                style={{textAlign: 'center'}}>
                                {title}
                            </Typography>
                        </Td>
                    </Tr>
                    {jsxData}
                </Tb>
            </Table>
        </InfoBox>
    );
}


export default DiscountsInfo;
