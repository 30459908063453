// IMPORT
import React, { useState } from 'react';

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from 'app/hooks';
// Actions
import { push } from 'features/notifications';
// Selectors
import { selectUser } from 'features/globalState';

// Material-UI
import { Typography, Button } from '@material-ui/core';
import { TableHead as Th, TableBody as Tb } from '@material-ui/core';
import { TableCell as Td, TableRow as Tr } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import InfoBox from 'components/reusable/InfoBox';
import Table from 'components/reusable/Table';
import RoleHide from 'components/reusable/RoleHide';
import Dialog from 'components/reusable/Dialog';

// Utils
import axios from 'axios';

// Hooks
import useData from 'components/hooks/useData';

// Types
import type { ExtendedOrder, Discount } from 'components/types';

const styles = makeStyles((theme) => createStyles({
    boldText: {
        fontWeight: 'bold',
    },
}));

const initialData : ExtendedOrder = {
    _id: '', n: 0,
    agent: {
        _id: '',
        name: {
            first: '',
            last: '',
        },
    },
    creationDate: '',
    customer: { _id: '', name: '' },
    articles: [],
    total: 0,
    paymentMethod: '',
    lastModified: {
        date: '',
        user: ''
    },
};


type OrderInfoProps = {
    open: boolean,
    id: string,
    onClose: () => void,
    edit: (id: string) => void,
};

function OrderInfo(props: OrderInfoProps) {
    const classes = styles();
    const {open, onClose, edit, id} = props;

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    const [noEditsDialog, setNoEditsDialog] = useState(false);
    const [wantDeleteDialog, setWantDeleteDialog] = useState(false);
    
    const [loading, setLoading] = useState(false);

    const url = id === '' ? '' : `/api/orders/${id}`;
    const [data] = useData<ExtendedOrder>({url, initialData,
        onFetching: () => setLoading(true),
        onFetched: () => setLoading(false),
    });

    if (data.agent === null) {
        data.agent = {
            _id: 'id_utente_inesistente',
            name: {
                first: 'Utente',
                last: 'Inesistente',
            }
        };
    }

    const fullName = data.agent.name.first + ' ' + data.agent.name.last;

    const getPrice = (n: number, unitPrice: number, discount: number) => {
        const price = n * unitPrice;
        return price - (price * discount / 100)
    }
    const getDiscount = (n: number, discounts: Discount[]) => {
        // if (discounts === undefined) discounts = [];
        for (let c = 0; c < discounts.length; c++) {
            const d = discounts[c];
            // Finding right discount
            if (n >= d.from && n <= d.to) {
                return d.percent;
            }
        }
        return 0;
    };

    const opts = {minimumFractionDigits: 2, maximumFractionDigits: 2,
        style: 'currency', currency: 'EUR'};

    let total = 0;
    
    const fields = data.articles.map((v) => {
        // Overwritten discount || Default discount
        const discount = v.discount !== undefined ? v.discount :
            getDiscount(v.number, v.product.discounts);
        // Get price discounted
        const price = getPrice(v.number, v.product.price, discount);
        total += price;
        
        return (
            <Tr key={v._id}>
                <Td>{v.number}</Td>
                <Td>{v.product.name}</Td>
                <Td>{discount}%</Td>
                <Td>{price.toLocaleString(undefined, opts)}</Td>
            </Tr>
        );
    });

    // Round to 2 decimals
    const round = (n: number) => Math.round((n + Number.EPSILON) * 100) / 100;

    const actions = (
        <RoleHide>
            <Button
                variant="contained"
                onClick={() => setWantDeleteDialog(true)}>
                    Elimina
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    axios.head('/api/orders/p/' + id,
                        {validateStatus: (s) => s !== 501})
                        .then(() => edit(id)).catch((e) => {
                            return setNoEditsDialog(true);
                        });
                }}>
                    Modifica
            </Button>
        </RoleHide>
    );

    const handleDeleteOrder = () => {
        axios.delete('/api/orders/' + id)
        .then(() => {
            dispatch(push({
                severity: 'success',
                message: 'Ordine eliminato con successo!',
            }));
            onClose();
        })
        .catch(() => dispatch(push({
            severity: 'error',
            message: 'Errore: impossibile eliminare questo ordine.',
            duration: 4000,
        })))
        .finally(() => setWantDeleteDialog(false));
    };

    return (
        <InfoBox open={open} actions={actions}
            onClose={onClose} loading={loading}>
            <Typography variant="h4">
                Ordine n°{data.n} - {fullName}
            </Typography>
            <Typography variant="h5">
                {data.customer.name}
            </Typography>
            <div style={{height: 16}}></div>
            <Table disableMargin elevation={4}
                bgColor={round(total) !== round(data.total) &&
                user && user.role !== 'u' ? 'red' : ''}>
                <Th>
                    <Tr>
                        <Td>Pz</Td>
                        <Td>Descrizione</Td>
                        <Td>Sconto</Td>
                        <Td>Prezzo</Td>
                    </Tr>
                </Th>
                <Tb>
                    {fields}
                    <Tr>
                        <Td colSpan={3} className={classes.boldText}>
                            Totale
                        </Td>
                        <Td className={classes.boldText}>{total
                            .toLocaleString(undefined, opts)}</Td>
                    </Tr>
                </Tb>
            </Table>
            <Dialog open={noEditsDialog} title="Attenzione"
                cancelBtnVisibility={false}
                onClose={() => setNoEditsDialog(false)}>
                    Uno dei prodotti di questo ordine sono stati modificati. {}
                    Questo significa che l&apos;ordine in questione {}
                    non è più modificabile.
            </Dialog>
            <Dialog open={wantDeleteDialog} title="Attenzione"
                onCancel={() => setWantDeleteDialog(false)}
                onClose={handleDeleteOrder}>
                    Vuoi davvero eliminare questo ordine?
            </Dialog>
        </InfoBox>
    );
}

export default OrderInfo;
