import React from "react";
import MuiSnackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {useAppSelector, useAppDispatch} from '../app/hooks';
import { shift, closeNotification, selectNotification, selectNotificationIsOpen, NotificationType } from "./notifications";

const Alert = React.forwardRef((props: AlertProps, ref) =>
    <MuiAlert elevation={6} variant="filled" {...props} ref={ref}/>);

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

function Notification() {
    const dispatch = useAppDispatch();
    const notification = useAppSelector(selectNotification);
    const open = useAppSelector(selectNotificationIsOpen);
    const {severity, duration, message} = getNotification(notification);

    const onEntered = () => setTimeout(() => dispatch(closeNotification()), duration);
    const onExited = () => {
        if (notification !== null) {
            dispatch(shift());
        }
    }
    return (
        <MuiSnackbar open={open && notification !== null}
            TransitionComponent={SlideTransition}
            TransitionProps={{onEntered, onExited}}
        >
            <Alert severity={severity}>{message}</Alert>
        </MuiSnackbar>
    );
}

// Returns a valid notification if parameter is null or duration is not specified
function getNotification(notification: null | NotificationType) : NotificationType {
    return {severity: 'info', duration: 2000, message: '', ...notification};
}

export default Notification;