import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { extraReducers } from "./authActions";

// TYPES
import { RootState } from "../app/store";
import { WritableDraft } from "immer/dist/internal";
import { GlobalStateType, Tab } from './globalStateTypes'

const initialState : GlobalStateType = {
    user: null,
    tab: 'loading',
    loading: false,
    editing: false,
    id: '',
    mobileMenu: false,
};

// Most used reducers (letting share logic)
const setTabWithIdRed = (state: WritableDraft<GlobalStateType>, action: PayloadAction<[Tab, string]>) => {
    state.id = action.payload[1];
    state.tab = action.payload[0];
    state.mobileMenu = false;
};
export const setTabRed = (state: WritableDraft<GlobalStateType>, {type, payload}: PayloadAction<Tab>) => {
    setTabWithIdRed(state, {type, payload: [payload, '']})
}

export const globalStateSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        setTab: setTabRed,
        setTabWithId: setTabWithIdRed,
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditing: (state, action: PayloadAction<boolean>) => {
            state.editing = action.payload;
        },
        eraseId: (state) => {
            state.id = '';
        },
        toggleMobileMenu: (state) => {
            state.mobileMenu = !(state.mobileMenu);
        },
    },
    extraReducers,
});

export const {
    setTab, setTabWithId, setLoading,
    setEditing, toggleMobileMenu, eraseId,
} = globalStateSlice.actions;

export const selectUser = (state: RootState) => state.globalState.user;
export const selectTab = (state: RootState) => state.globalState.tab;
export const selectLoading = (state: RootState) => state.globalState.loading;
export const selectEditing = (state: RootState) => state.globalState.editing;
export const selectId = (state: RootState) => state.globalState.id;
export const selectMobileMenu = (state: RootState) => state.globalState.mobileMenu;

export default globalStateSlice.reducer;