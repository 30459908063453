// IMPORT
import React, { Fragment } from 'react';
// Actions
import { decreaseQuantity, increaseQuantity,
    setDiscount, setQuantity } from './orderEditReducer';

// Material-UI
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Material Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// Types
import type { PayloadAction } from '@reduxjs/toolkit';
import type { PopulatedArticle } from './orderEditReducer';


const useStyles = makeStyles((theme) => createStyles({
    numTextField: {
        width: '5ch',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            transform: 'translate(2px, 8px)',
        },
    },
    discountInput: {
        textAlign: 'center',
        padding: `${theme.spacing(.5)}px 0`,
    },
    discountTextField: {
        width: '4ch',
        margin: 0,
    },
    discountFields: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        transform: 'translate(2px, -2px)',
    },
    xsNone: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));


type OrderEditFieldsProps = {
    catalogIndex: number,
    article: PopulatedArticle,
    disabled?: boolean,
    dispatch: (action: PayloadAction<any>) => void,
}

export function QuantityField({catalogIndex, article, disabled, dispatch}: OrderEditFieldsProps) {
    const classes = useStyles();
    return (
        <div>
            <IconButton onClick={() => dispatch(decreaseQuantity({
                catalogIndex, productIndex: article.index,
            }))}
                disabled={disabled} className={classes.xsNone}>
                <RemoveIcon />
            </IconButton>
            {!disabled ? (
                <TextField
                    value={article.number}
                    size="small"
                    className={classes.numTextField}
                    inputProps={{className: classes.discountInput}}
                    onClick={(e) => (e.target as HTMLTextAreaElement).select()}
                    onChange={(e) => dispatch(setQuantity({
                        catalogIndex, productIndex: article.index,
                        newValue: e.target.value,
                    }))}/>
            ) : article.number}
            <IconButton onClick={() => dispatch(increaseQuantity({
                catalogIndex, productIndex: article.index,
            }))}
                disabled={disabled} className={classes.xsNone}>
                <AddIcon />
            </IconButton>
        </div>
    );
}

export function DiscountField({catalogIndex, article, disabled, dispatch}: OrderEditFieldsProps) {
    const classes = useStyles();
    if (!(article.number > 0 || !disabled)) {
        return <Fragment>{article.discount} %</Fragment>;
    }
    return (
        <div className={classes.discountFields}>
            <Tooltip title="Doppio clic per info">
                <TextField
                    value={article.discount}
                    disabled={article.number <= 0}
                    size="small"
                    className={classes.discountTextField}
                    inputProps={{className:
                            classes.discountInput}}
                    onClick={(e) => (e.target as HTMLTextAreaElement).select()}
                    onChange={(e) => dispatch(setDiscount({
                        catalogIndex, productIndex: article.index,
                        newValue: e.target.value,
                    }))}/>
            </Tooltip>
            <div>%</div>
        </div>
    );
}
    