// IMPORT
import React from 'react';

// Hooks
import { useAppDispatch } from 'app/hooks';
// Actions
import { setTab } from 'features/globalState';

// Material-UI
import { Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

// Hooks
import useMenuButtons from './useMenuButtons';

const rootStyles = makeStyles({ // styles
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: '2rem',
    },
    cards: {
        textAlign: 'center',
        paddingBottom: '1rem',
        margin: '1rem',
        cursor: 'pointer',
    },
    iconsCards: {
        padding: '1rem',
        paddingBottom: '.5rem',
        marginBottom: '.5rem',
    },
});

function FullScreenMenu() {
    const classes = rootStyles(); // styles
    const dispatch = useAppDispatch();

    const cardProps = useMenuButtons();

    // Cards becoming React Element with Array.map()
    // 'elevation' edits div shadow
    const cards = cardProps.map((cardProps, index) => (
        <Paper className={classes.cards}
            elevation={5}
            key={index}
            onClick={() => dispatch(setTab(cardProps.tab))}>
            <Paper className={classes.iconsCards}
                elevation={0}
                style={{backgroundColor: cardProps.bgColor}}>
                <cardProps.icon style={{fontSize: 200}}/>
            </Paper>
            <Typography variant="h6" style={{fontSize: '2rem'}}>
                {cardProps.title}</Typography>
        </Paper>
    ));

    return (
        <Container className={classes.root}>
            {cards}
        </Container>
    );
}

export default FullScreenMenu;
