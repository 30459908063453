// IMPORT
import React from 'react';

// Hooks
import { useAppDispatch, useAppSelector } from '../app/hooks';
// Selectors
import { selectUser } from '../features/globalState';
// Actions
import { logout } from '../features/authActions';

// Material-UI
import { Button, Typography, Popper } from '@material-ui/core';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { ClickAwayListener, Grow, useTheme } from '@material-ui/core';

// Material Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Types
import type { ReferenceObject } from 'popper.js';


// Props Types
type UserBoxProps = {
    anchorEl: null | ReferenceObject | (() => ReferenceObject),
    onClose: () => void,
};

function UserBox(props: UserBoxProps) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    // Composing User Name
    const user = useAppSelector(selectUser) || {
        name: {
            first: 'Utente',
            last: 'Utente'
        },
        email: 'utente@utente.it',
        role: 'u',
    };
    const userName = `${user.name.first} ${user.name.last}`;
    // Getting Role Name
    const getRoleName = () => {
        switch (user.role) {
        case 'a':
        case 's':
            return 'Amministratore';
        default:
            return 'Agente';
        }
    };

    /* Using Popper component
        To revise: https://material-ui.com/api/popper/
        To do the following, I took the code from here:
        https://stackoverflow.com/questions/55143914/material-ui-clickawaylistener-close-when-clicking-itself
        Other Useful Links:
        https://material-ui.com/components/click-away-listener/
        https://material-ui.com/components/transitions/
        Examples in <Menu /> Guide
        (!!! : These examples are made with Popover and not Popper)
        https://material-ui.com/components/menus/
    */
    return (
        <Popper
            anchorEl={props.anchorEl}
            placement="right"
            style={{zIndex: theme.zIndex.drawer + 2}}
            open={Boolean(props.anchorEl)}
            transition
            modifiers={{
                offset: {
                    enabled: true,
                    offset: '900, 0',
                },
            }}>
            {({TransitionProps}) => (
                <ClickAwayListener onClickAway={() => props.onClose()}>
                    <Grow {...TransitionProps} timeout={200}
                        style={{transformOrigin: 'top right'}}>
                        <Card style={{
                            textAlign: 'center',
                            width: '12rem',
                        }}>
                            <CardContent>
                                <AccountCircleIcon color="primary"
                                    style={{fontSize: '5rem'}}/>
                                <Typography variant="h6">{userName}</Typography>
                                <Typography>{getRoleName()}</Typography>
                            </CardContent>
                            <CardActions style={{float: 'right'}}>
                                <Button onClick={() => {
                                    props.onClose();
                                    dispatch(logout());
                                }}>
                                    Logout</Button>
                            </CardActions>
                        </Card>
                    </Grow>
                </ClickAwayListener>
            )}
        </Popper>
    );
}

export default UserBox;
