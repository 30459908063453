import { useRef } from "react";

function useDelayedToggle(func: (bool: boolean) => void, ms: number) : [() => void, () => void] {
    const timer = useRef<NodeJS.Timeout | null>(null);

    const turnON = () => {
        timer.current = setTimeout(() => {
            timer.current = null;
            func(true);
        }, ms)
    }
    const turnOFF = () => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
            timer.current = null;
            return;
        }
        func(false);
    }

    return [turnON, turnOFF];
}

export default useDelayedToggle;