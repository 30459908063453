// IMPORT
import React, { Fragment } from 'react';
import { SwitchField } from 'components/reusable/EditBox/EditBoxFields';

// Material-UI
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Types
import type { PayloadAction } from '@reduxjs/toolkit';
import type { JSXFieldProps } from 'components/reusable/EditBox/EditBoxFields';
import type { Edit } from 'components/reusable/EditBox/EditBoxTypes';

const useStyles = makeStyles((theme) => createStyles({
    textfields: {
        width: '5ch',
        margin: `${theme.spacing(0.625)}px ${theme.spacing(1)}px 0`,
    },
    input: {
        textAlign: 'center',
        padding: `${theme.spacing(.5)}px 0`,
    },
    fields: {
        display: 'flex',
        alignItems: 'end',
    },
}));


function DiscountField(props: JSXFieldProps) {
    const classes = useStyles();
    const {data, shaking} = props;

    const onEdit = (action: PayloadAction<Edit<string>>) => {
        const { key, newValue } = action.payload;
        const { _id } = data;
        if (_id)
            props.onChange(_id, key, newValue);
    };

    return (
        <Fragment>
            <div className={classes.fields}>
                <div className={classes.fields}>
                    <Typography>Da</Typography>
                    <SwitchField
                        dataKey="from"
                        data={data.from}
                        dispatch={onEdit}
                        shake={shaking}
                        variant='standard'
                        margin="dense"
                        className={classes.textfields}
                        inputProps={{className: classes.input}}
                    />
                    {/* margin="dense" inputProps={{className: classes.input}} */}
                </div>
                <div className={classes.fields}>
                    <Typography>A</Typography>
                    <SwitchField
                        dataKey="to"
                        data={data.to}
                        dispatch={onEdit}
                        shake={shaking}
                        variant='standard'
                        margin="dense"
                        className={classes.textfields}
                        inputProps={{className: classes.input}}
                    />
                </div>
                <Typography>pz</Typography>
            </div>

            <div className={classes.fields}>
                    <SwitchField
                        dataKey="percent"
                        data={data.percent}
                        dispatch={onEdit}
                        shake={shaking}
                        variant='standard'
                        margin="dense"
                        className={classes.textfields}
                        inputProps={{className: classes.input}}
                    />
                <Typography>%</Typography>
            </div>
        </Fragment>
    );
};

export default DiscountField;

/*
    <TextField
        size="small"
        className={clsx(classes.textfields,
            Boolean(shakeClass) && error.percent && shakeClass)}
        margin="dense"
        inputProps={{className: classes.input}}
        error={error.percent}
        value={data.percent}
        disabled={disabled}
        onChange={(e) =>
            onChange(data._id, 'percent', e.target.value)}>
    </TextField>
*/