// REDUX
import { createSlice } from "@reduxjs/toolkit";

// TYPES
import { RootState } from "../app/store";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState : { value: boolean } = {
    value: false,
};


export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState,
    reducers: {
        setDarkMode: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
        toggleDarkMode: (state) => {
            state.value = !(state.value);
        }
    },
});

export const {
    setDarkMode, toggleDarkMode,
} = darkModeSlice.actions;

export const selectDarkMode = (state: RootState) => state.darkMode.value;

export default darkModeSlice.reducer;