// IMPORT

// Material-UI
import { useTheme } from "@material-ui/core";

// Material Icons
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';

// Types
import type { SvgIconTypeMap } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";
import type { Tab } from "features/globalStateTypes";

export type MenuButton = {
    icon: OverridableComponent<SvgIconTypeMap>,
    title: string,
    bgColor: string,
    tab: Tab,
};

function useMenuButtons(props?: {wantUsersButton?: boolean}) {
    const theme = useTheme();
    const usingDarkTheme = theme.palette.type === 'dark';

    // Buttons List
    const menuButtons : MenuButton[] = [
        {
            icon: PeopleIcon,
            title: 'Clienti',
            bgColor: usingDarkTheme ? '#1c4a6a' : '#a5e6ff',
            tab: 'customers',
        },
        {
            icon: DescriptionIcon,
            title: 'Ordini',
            bgColor: usingDarkTheme ? '#296636' : '#8fdec1',
            tab: 'orders',
        },
        {
            icon: ListIcon,
            title: 'Prodotti',
            bgColor: usingDarkTheme ? '#5f7239' : '#b3dc99',
            tab: 'products',
        },
    ];

    if (props && props.wantUsersButton) {
        menuButtons.push({
            icon: PersonIcon,
            title: 'Utenti',
            bgColor: usingDarkTheme ? '#1c4a6a' : '#a5e6ff',
            tab: 'users',
        });
    }

    return menuButtons;
}

export default useMenuButtons;