// IMPORT
import React, { useState, Fragment } from 'react';

// REDUX
// Hooks
import { useAppDispatch } from 'app/hooks';
// Actions
import { push } from 'features/notifications';
import { setLoading } from 'features/globalState';

// Material-UI
import { Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { Divider, Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import TabHead from 'components/reusable/TabHead';
import UserEdit from 'components/reusable/EditBox/EditBox';
import userFields from './usersFields.json';
import Dialog from 'components/reusable/Dialog';

// Hooks
import useData from 'components/hooks/useData';
import useFilteredData from 'components/hooks/useFilteredData';
import useDelayedToggle from 'components/hooks/useDelayedToggle';

// Utils
import axios from 'axios';

// Types
import type { User } from 'components/types';

const usersStyles = makeStyles((theme) => createStyles({
        root: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            height: '100%',
            overflow: 'auto',
        },
        searchElements: {
            margin: '0 .5rem',
        },
        listRoot: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
        listItems: {
            paddingLeft: theme.spacing(3),
            '&:hover': {
                backgroundColor: theme.palette.action.focus,
            },
            textTransform: 'uppercase',
            cursor: 'pointer',
        },
}));

function Users(props: {className?: string}) {
    const classes = usersStyles();

    const dispatch = useAppDispatch();
    const [userEdit, setUserEdit] = useState('');
    const [searchField, setSearchField] = useState('');
    const [wantDeleteDialog, setWantDeleteDialog] = useState('');

    const filter = ({name, email} : User, search: string) => {
        search = search.toLowerCase();
        return name.first.toLowerCase().includes(search) ||
        name.last.toLowerCase().includes(search) ||
        email.toLowerCase().includes(search);
    };


    const [setLoadingTrue, setLoadingFalse] = useDelayedToggle((bool) => dispatch(setLoading(bool)), 100);

    const [data, fetchData] = useData<User[]>({url: '/api/users', initialData: [],
        onFetching: setLoadingTrue,
        onFetched: setLoadingFalse,
    });
    const filteredData = useFilteredData<User>({data, search: searchField, filter});
    
    // useEffect(() => fetchData(), [searchField]);

    const jsxData = filteredData.map((v, i, a) => {
        return (
            <Fragment key={v._id}>
                <ListItem className={classes.listItems}
                    onClick={() => setUserEdit(v._id)}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        setWantDeleteDialog(v._id);
                    }}>
                    <ListItemText
                        primary={v.name.first + ' ' + v.name.last + ' - ' +
                            v.role.toUpperCase()}
                        secondary={v.email}
                    />
                </ListItem>
                {i === (a.length - 1) ? null : <Divider />}
            </Fragment>
        );
    });

    const list = (
        <Paper className={classes.listRoot}>
            <List>
                {jsxData}
            </List>
        </Paper>
    );

    const usersView = (
        <Fragment>
            <TabHead name="Utenti"
                searchbarState={[searchField, setSearchField]}
                buttonText="Nuovo Utente"
                onButtonClick={() => setUserEdit('--new')}
            />
            {data.length > 0 ? list : null}
        </Fragment>
    );

    return (
        <div className={props && props.className} style={{height: 'calc(100% - 64px)'}}>
            <Container maxWidth="lg" disableGutters className={classes.root}>
                {usersView}
                <UserEdit id={userEdit}
                    open={Boolean(userEdit)}
                    maxWidth="sm"
                    height="80%"
                    title="Utente"
                    template={userFields}
                    getUrl="/api/users/:id?divideName"
                    postUrl="/api/users/:action/:id"
                    onClose={() => {
                        setUserEdit('');
                        fetchData();
                    }}/>
                <Dialog open={Boolean(wantDeleteDialog)}
                    title="Attenzione"
                    onClose={() => {
                        axios.delete('/api/users/' + wantDeleteDialog)
                            .then(() => {
                                fetchData();
                                dispatch(push({
                                    severity: 'success',
                                    message: 'Utente eliminato con successo!',
                                }));
                            })
                            .catch(() => dispatch(push({
                                severity: 'error',
                                message: 'Errore: impossibile eliminare questo utente.',
                                duration: 4000,
                            })))
                            .finally(() => setWantDeleteDialog(''));
                    }}
                    onCancel={() => setWantDeleteDialog('')}
                >
                    Vuoi davvero eliminare questo utente?
                </Dialog>
            </Container>
        </div>
    );
}

export default Users;
