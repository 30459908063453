// IMPORT
import React from "react";

// Material-UI
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from '@material-ui/core';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';

// Actions
import { delete_AddRem, editField, edit_AddRem } from "./EditBoxReducer";
import { add_AddRem } from "./EditBoxReducer";

// Effects
import keyframesEffects from "components/reusable/keyframesEffects";

// Types
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AddRemData, Field } from "./EditBoxTypes";
import type { Theme } from "@material-ui/core";

// Utils
import clsx from "clsx";

const useStyles = makeStyles<Theme, {error?: boolean}>((theme) => createStyles({
    textfield: {
        marginLeft: theme.spacing(2),
        width: 240,
    },
    addRemRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1),
        border: p => p.error ? `solid 1px ${theme.palette.error.main}` : 'solid 1px #00000000',
        borderRadius: 4,
    },
    addRemFields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        marginTop: 1,
    },
    ...keyframesEffects.shake,
}));

export type JSXFieldProps = {
    data: AddRemData,
    onChange: (_id: string, field: string, newValue: any) => void,
    shaking: boolean
};

export type JSXFieldType = React.ComponentType<JSXFieldProps>;

type FieldProps = {
    dataKey: string,
    data: Field<any>,
    dispatch: (action: PayloadAction<any>) => void,
    shake: boolean,
    
    className?: string,
    // For TextField:
    variant?: 'standard' | 'filled' | 'outlined',
    margin?: 'none' | 'dense' | 'normal',
    inputProps?: object,
    // For AddRem Field:
    JSXField?: JSXFieldType,
}

export function TextField({dataKey, data, dispatch, shake, ...otherProps}: FieldProps) {
    const { variant = 'outlined', margin, className, inputProps } = otherProps;
    const { type, value, error, disabled, multiline } = data;
    const classes = useStyles({error});

    return (
        <MuiTextField
            variant={variant}
            size="small"
            multiline={multiline}
            type={type === 'password' ? 'password' : 'text'}
            value={String(value)}
            error={error}
            disabled={disabled}
            margin={margin}
            onChange={(e) =>
                dispatch(editField({key: dataKey, newValue: e.target.value}))
            }
            className={clsx(classes.textfield, className, {
                [classes.shake]: shake && error,
            })}
            inputProps={inputProps}
        >
        </MuiTextField>
    );
}

export function SelectField({dataKey, data, dispatch, shake, className}: FieldProps) {
    const {value, error, disabled, options } = data;
    const classes = useStyles({error});

    return (
        <FormControl
            className={clsx(classes.textfield, className, {
                [classes.shake]: shake && error,
            })}
            error={error}
        >
            <Select
                value={value}
                displayEmpty
                disabled={disabled}
                onChange={(e) =>
                    dispatch(editField({key: dataKey, newValue: e.target.value as string}))
                }
            >
                {options && options.map((option) => (
                    <MenuItem
                        key={option.key}
                        value={option.key}
                        disabled={option.disabled}
                    >
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function AddRemField({dataKey, data, dispatch, shake, JSXField}: FieldProps) {
    const { error, disabled } = data;
    const classes = useStyles({error});

    const fields = (data.value as AddRemData[]).map((fieldObj) => {
        const { _id } = fieldObj;
        if (_id === undefined || JSXField === undefined) return null;

        return (
        <div className={classes.addRemFields} key={_id}>
            <JSXField
                data={fieldObj}
                onChange={(_id: string, field: string, newValue: any) =>
                    dispatch(edit_AddRem({
                        key: dataKey, field, _id, newValue,
                    }))}
                shaking={shake}
            />
            <IconButton size="small"
                onClick={() => dispatch(delete_AddRem({key: dataKey, _id}))}>
                <CloseIcon fontSize="small"/>
            </IconButton>
        </div>
    )});

    return (
        <div className={clsx(classes.addRemRoot, {
            [classes.shake]: shake && error,
        })}>
            <Button
                variant="contained"
                disableElevation
                onClick={() => dispatch(add_AddRem({key: dataKey}))}
                size="small"
                disabled={disabled}>
                Aggiungi
            </Button>
            {fields}
        </div>
    );
}

export function SwitchField(props: FieldProps) {
    const Field = (() => {
        switch (props.data.type) {
            case 'select':
                return SelectField;
            case 'addrem':
                return AddRemField;
            default:
                return TextField;
        }
    })();
    return <Field {...props} />;
}