import React from 'react';

// Material-UI
import { Paper, TableContainer } from '@material-ui/core';
import MaterialTable from '@material-ui/core/Table';
import { makeStyles, createStyles } from '@material-ui/core';

// Types
import type { Theme } from '@material-ui/core';
import type { Property } from 'react/node_modules/csstype';

const useStyles = makeStyles<Theme, TableProps>((theme) => createStyles({
    listRoot: {
        margin: (p) => p.disableMargin ? 0 : theme.spacing(2),
        marginTop: (p) => p.disableMargin ? 0 : theme.spacing(1),
    },
}));

type TableProps = {
    children?: React.ReactNode,
    elevation?: number,
    disableMargin?: boolean,
    bgColor?: Property.Background<string | number>,
};

function Table(props: TableProps) {
    const classes = useStyles(props);
    const {children, elevation, bgColor} = props;

    return (
        <Paper className={classes.listRoot} elevation={elevation}
            style={bgColor ? {background: bgColor} : {}}>
            <TableContainer>
                <MaterialTable>
                    {children}
                </MaterialTable>
            </TableContainer>
        </Paper>
    );
}

Table.defaultProps = {
    children: <div></div>,
    elevation: 1,
    disableMargin: false,
};

export default Table;
