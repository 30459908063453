// // Regex
import {email, pecMail, phone, iva, taxCode, iban} from 'validation';

// // Validation
import countryCodes from 'validation/countryCodes.json';

// Types
import type { InputDataType } from "components/reusable/EditBox/EditBoxTypes";

const addInternatPrefix = (value: string) => {
    value = value.replaceAll(' ', '');
    if (!(value.substring(0, 1) === '+')) {
        value = '+39' + value;
    }
    return value;
};

const validateIfItaly = (validate: (newValue: string) => boolean) =>
    (newValue: string, get: (key: string) => string) => {
    if (get('country') === 'IT') {
        return validate(newValue);
    }
    return true;
};
const requiredIfItaly = (get: (key: string) => string) =>
    get('country') === 'IT';

const customerFields : InputDataType = {
    name: {
        name: 'Ragione sociale',
        required: true,
    },
    address: {
        name: 'Indirizzo',
        required: true,
    },
    postCode: {
        name: 'CAP',
        required: true,
        validate: validateIfItaly((newValue) => /^[0-9]{5}$/.test(newValue))
    },
    town: {
        name: 'Città',
        required: true,
    },
    province: {
        name: 'Provincia',
        required: true,
    },
    country: {
        name: 'Nazione',
        required: true,
        type: 'select',
        options: countryCodes,
        defaultValue: 'IT',
        validateAll: true,
    },
    phone: {
        name: 'Telefono 1',
        required: true,
        preValidate: addInternatPrefix,
        validate: (newValue: string) => phone.test(newValue),
    },
    phone2: {
        name: 'Telefono 2',
        required: false,
        preValidate: addInternatPrefix,
        validate: (newValue: string) => phone.test(newValue),
    },
    email: {
        name: 'Email',
        required: true,
        validate: (newValue: string) => email.test(newValue),
    },
    email2: {
        name: 'Email 2',
        required: false,
        validate: (newValue: string) => email.test(newValue),
    },
    iva: {
        name: 'P. Iva',
        required: true,
        validate: validateIfItaly((newValue) => iva(newValue)),
    },
    taxCode: {
        name: 'Codice Fiscale',
        required: requiredIfItaly,
        validate: validateIfItaly((newValue) => iva(newValue) || taxCode(newValue)),
    },
    pecMail: {
        name: 'PEC',
        required: requiredIfItaly,
        validate: validateIfItaly((newValue) => pecMail.test(newValue)),
    },
    sdiCode: {
        name: 'SDI',
        required: requiredIfItaly,
        validate: validateIfItaly((newValue) => /^[0-9A-Z]{7}$/.test(newValue)),
    },
    iban: {
        name: 'IBAN',
        required: true,
        validate: (newValue: string) => iban(newValue),
    },
    contactPerson: {
        name: 'Referente',
        required: false,
    },
    type: {
        name: 'Categoria',
        type: 'select',
        defaultValue: 'wholesaler',
        required: true,
        options: [
            {
                value: 'Grossisti',
                key: 'wholesaler',
            },
            {
                value: 'Parafarmacie',
                key: 'paraPharmacy',
            },
            {
                value: 'Farmacie',
                key: 'pharmacy',
            },
            {
                value: 'Erboristerie',
                key: 'herbalistShop',
            },
            {
                value: 'Cooperative',
                key: 'cooperative',
            },
            {
                value: 'Sanitarie',
                key: 'sanitary',
            },
            {
                value: 'Concessionari',
                key: 'dealer',
            },
            {
                value: 'Agenti',
                key: 'agent',
            },
            {
                value: 'Varie',
                key: 'varying',
            },
            {
                value: 'Case di riposo',
                key: 'restHome',
            },
        ],
    },
    notes: {
        name: 'Note',
        multiline: true,
    }

};

export default customerFields;
