// IMPORT
import React, { useEffect, useReducer, useState } from "react";
import { DiscountField, QuantityField } from "./OrderEditFields";
import orderEditReducer, { areThereEdits, getSalePrice,
    initialise, initialState } from "./orderEditReducer";
import useOrderEditStyles from "./useOrderEditStyles";

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from 'app/hooks';
// Actions
import { eraseId, setTab, setLoading as changeLoading } from 'features/globalState';
import { push } from "features/notifications";
// Selectors
import { selectId, selectLoading } from "features/globalState";

// Material-UI
import { Button, Container, Grid, TextField, useTheme,
    InputAdornment, Slide, Typography } from "@material-ui/core";
import { TableHead as Th, TableBody as Tb } from '@material-ui/core';
import { TableCell as Td, TableRow as Tr } from '@material-ui/core';
// Material Icons
import SearchIcon from '@material-ui/icons/Search';

// Components
import Dialog from "components/reusable/Dialog";
import ScrollableTabs from "components/reusable/ScrollableTabs";
import Table from "components/reusable/Table";
import DiscountsInfo from "./DiscountsInfo";

// Hooks
import useFilteredData from "components/hooks/useFilteredData";

// Utils
import clsx from "clsx";
import axios from "axios";

// Types
import type { PopulatedArticle } from './orderEditReducer';

function OrderEdit() {
    const theme = useTheme();
    const isDarkModeEnabled = theme.palette.type === 'dark';

    const classes = useOrderEditStyles();
    const appDispatch = useAppDispatch();
    
    // REDUX STATE
    const id = useAppSelector(selectId);
    const loading = useAppSelector(selectLoading);
    const setLoading = (newState: boolean) => appDispatch(changeLoading(newState));

    // LOCAL STATE
    const [data, dispatch] = useReducer(orderEditReducer, initialState);
    const [catalogIndex, setCatalogIndex] = useState(0);
    const [searchField, setSearchField] = useState('');
    const [discountInfo, setDiscountInfo] = useState('');
    const [discardDialog, setDiscardDialog] = useState(false);

    // SEARCH
    const filter = ({product}: PopulatedArticle, search: string) => {
        return product.name.toLowerCase().includes(search.toLowerCase());
    }
    const filteredData = useFilteredData<PopulatedArticle>({
        data: data.data.length > 0 ? data.data[catalogIndex].articles : [],
        search: searchField, filter,
    });

    const newOrder = id.includes('--new');
    const title = (newOrder ? 'Nuovo ' : 'Modifica ') + 'Ordine:';

    // getUrl: Look at the definition. There is
    // id.substring(6) because with a new order, id = `--new*${customerId}` .
    // In JS, if you pass an out-of-range argument substring() ,
    // It returns '', it does not throw an Exception like in Java.
    const getUrl = '/api/orders/p/' + (newOrder ? `new/${id.substring(6)}` : id);
    const postUrl = '/api/orders/' + (newOrder ? 'insert' : 'edit/' + id);
    
    
    const opts = {minimumFractionDigits: 2, maximumFractionDigits: 2,
        style: 'currency', currency: 'EUR'};

    // EVENTS
    const handleInitialise = () => {
        if (id === '') return;
        setLoading(true);
        axios.get(getUrl)
            .then((({data}) => {
                dispatch(initialise(data.result));
            }))
            .catch(handleError)
            .finally(() => setLoading(false));
    };
    useEffect(handleInitialise, []);

    const handleSubmit = () => {
        if (!(areThereEdits(data))) {
            appDispatch(push({
                severity: 'info',
                message: 'Nessuna modifica effettuata.',
            }));
            return handleClose(true);
        }
        setLoading(true);
        axios.post(postUrl, {
            articles: JSON.stringify(data.articles),
            paymentMethod: 'Bonifico',
            total: data.total,
            customer: newOrder ? data.customer._id : undefined,
        })
            .then(() => {
                handleClose(true);
                appDispatch(push({
                    severity: 'success',
                    message: `Ordine ${newOrder ? 'inviato' : 'salvato'}!`,
                }));
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    };

    const handleError = () => {
        appDispatch(push({
            severity: 'error',
            message: 'Errore di comunicazione col server.',
        }))
    };
    
    const handleClose = (doWhileEditing = false) => {
        if (!loading) {
            const exit = () => appDispatch(eraseId());
            // If there no edits, just closing:
            if (!(areThereEdits(data))) {
                return exit();
            }
            if (!doWhileEditing) {
                return setDiscardDialog(true);
            }
            return exit();
        }
        
    };

    const handleCloseDialog = (wantDiscard?: boolean) => {
        setDiscardDialog(false);
        if (wantDiscard) {
            handleClose(true);
        }
    };
    

    const products = filteredData.map((article) => {
        const { product } = article;
        const salePrice = getSalePrice(article);
        const price = article.number * salePrice;

        return (
            <Tr className={clsx(classes.tableRow,
                article.number > 0 && classes.tableRowNotEmpty)} key={product._id}>
                <Td className={classes.cell + ' ' + classes.descriptionCell}>
                    {product.name}
                </Td>
                <Td className={classes.cell + ' ' + classes.smNone}>
                    {product.price.toLocaleString(undefined, opts)}
                </Td>
                <Td className={classes.cell + ' ' + classes.xsNone}>
                    {product.iva}%
                </Td>
                <Td className={classes.cell}>
                    <QuantityField
                        catalogIndex={catalogIndex}
                        article={article}
                        dispatch={dispatch}
                        disabled={loading}
                    />
                </Td>
                <Td
                    className={classes.cell + ' ' + classes.smNone}
                    onDoubleClick={() => setDiscountInfo(product._id)}
                >
                    <DiscountField
                        catalogIndex={catalogIndex}
                        article={article}
                        dispatch={dispatch}
                        disabled={loading}
                    />
                </Td>
                <Td className={classes.cell + ' ' + classes.xsNone}>
                    {salePrice.toLocaleString(undefined, opts)}
                </Td>
                <Td className={classes.cell}>
                    {price.toLocaleString(undefined, opts)}
                </Td>
            </Tr>
        );
    });

    const tabBody = (
        <Tb>{products}</Tb>
    );

    const tableHead = (
        <Th>
            <Tr>
                <Td className={classes.cell + ' ' + classes.descriptionCell}
                    style={{padding: '1rem'}}>
                    Descrizione
                </Td>
                <Td className={classes.cell + ' ' + classes.smNone}>
                    P.P.C.
                </Td>
                <Td className={classes.cell + ' ' + classes.xsNone}>
                    Iva
                </Td>
                <Td className={classes.cell}>
                    Q.tà
                </Td>
                <Td className={classes.cell + ' ' + classes.smNone}>
                    Sconto
                </Td>
                <Td className={classes.cell + ' ' + classes.xsNone}>
                    Pr. cess.
                </Td>
                <Td className={classes.cell}>
                    Totale
                </Td>
            </Tr>
        </Th>
    );

    const actions = <div className={classes.actionsBox}>
        <Button
            variant="contained"
            onClick={() => handleClose()}
            className={classes.searchElements}
            disabled={loading}>
                Annulla
        </Button>
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.searchElements}
            disabled={loading}>
                {newOrder ? 'Invia' : 'Salva'}
        </Button>
    </div>;

    const tabHead = ( //
        <Grid className={classes.searchContainer} alignItems="center"
            container direction="row" justifyContent="space-between" wrap="nowrap">
            <Typography
                variant="h6"
                className={classes.searchElements+' '+classes.title}>
                {title}
            </Typography>
            <TextField variant="outlined" size="small" color="primary"
                className={classes.searchElements}
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {actions}
        </Grid>
    );

    return (
        <Slide in={Boolean(id)} direction="up"
            mountOnEnter unmountOnExit
            onEntering={() => document.body.style.overflow = 'hidden'}
            onEntered={() => {
                document.body.style.overflow = 'auto';
                // setLoading(true);
                // setTimeout(async () => load(), 1000);
            }}
            onExiting={() => document.body.style.overflow = 'hidden'}
            onExited={() => {
                document.body.style.overflow = 'auto';
                appDispatch(setTab('orders'));
            }}
            timeout={200}
            style={{flexGrow: 1, display: 'flex'}}
        >
            <Container maxWidth="lg" disableGutters className={classes.root}>
                <Typography variant="h6" className={classes.customerName}>
                    {data.customer.name}
                </Typography>

                {tabHead}

                <ScrollableTabs
                    tabIndex={catalogIndex}
                    namesList={data.data}
                    setTab={setCatalogIndex}
                    txtColor={isDarkModeEnabled ? '#c5ceff' : undefined}
                    // bgColor={isDarkModeEnabled ? '#363e74' : '#ffffffd1'}
                >
                    <Table>
                        {tableHead}
                        {tabBody}
                    </Table>
                    <DiscountsInfo
                        open={Boolean(discountInfo)}
                        id={discountInfo}
                        onClose={() => setDiscountInfo('')}
                    />
                </ScrollableTabs>
                
                <Typography variant="subtitle1" className={classes.total}>
                    Totale: {data.total.toLocaleString(undefined, opts)}
                </Typography>

                <Dialog
                    open={discardDialog}
                    title="Vuoi annullare le modifiche?"
                    onClose={() => handleCloseDialog(true)}
                    onCancel={() => handleCloseDialog()}
                >
                    Perderai tutto ciò che hai modificato finora.
                </Dialog>
            </Container>
        </Slide>
    );
}

export default OrderEdit;