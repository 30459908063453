import { configureStore } from '@reduxjs/toolkit';

// Reducers
import globalStateReducer from '../features/globalState';
import notificationsReducer from 'features/notifications';
import darkModeReducer from 'features/darkMode';

// Types
import type { Action, ThunkAction } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    globalState: globalStateReducer,
    notifications: notificationsReducer,
    darkMode: darkModeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;