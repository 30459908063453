// IMPORT
import React, { Fragment, useState } from 'react';

// Hooks
import { useAppDispatch, useAppSelector } from '../app/hooks';
// Actions
import { toggleMobileMenu } from '../features/globalState';
import { toggleDarkMode } from '../features/darkMode';
// Selectors
import { selectUser, selectLoading, selectTab } from '../features/globalState';

// Material-UI
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { LinearProgress, makeStyles, createStyles } from '@material-ui/core';

// Material Icons
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DarkModeIcon from '@material-ui/icons/Brightness6';

// Components
import UserBox from './UserBox';

// Types
import type { Theme } from '@material-ui/core';

type HeaderProps = {
    menuWidth: number,
    disableLeftPadding: boolean, // In the styles, it's explained
}

const headerStyle = makeStyles<Theme, HeaderProps>((theme) => createStyles({ // styles
    appBar: {
        [theme.breakpoints.up('sm')]: { // (Not in the mobile)
            // The title will have / not have left padding
            // if specified in the props --> look in index.js to study
            paddingLeft: (props) => props.disableLeftPadding ? 0 :
                props.menuWidth,
            zIndex: theme.zIndex.drawer + 1,
            transition: 'padding .3s ease 0s',
        },
    },
    menuButton: {
        // Because of the power of flexbox:
        [theme.breakpoints.up('sm')]: {
            // The title remains centered,
            // Because there IS the menu button, but it's not visible:
            visibility: 'hidden',
        },
        [theme.breakpoints.up('lg')]: {
            // The title moves to the left,
            // Because there's NO MORE the menu button
            display: 'none',
        },
    },
    icons: {
        color: 'white',
    },
}));


function Header(props: HeaderProps) {
    const classes = headerStyle(props); // styles
    const dispatch = useAppDispatch();

    const logged = useAppSelector(selectUser) !== null;
    const loading = useAppSelector(selectLoading);

    // UserBox Anchor element (User IconButton)
    // (userbox is a box with name of the user, role and logout button)
    const [userbox, setUserbox] = useState<null | (EventTarget & HTMLButtonElement)>(null);

    const title = (
        <Typography variant="h6" noWrap>
            Dari Salus Portal
        </Typography>
    );

    // Understand if user's displaying full screen menu
    const isFullScreenMenu = useAppSelector(selectTab) === 'fullScreenMenu';

    // If logged, following content is displayed:
    // (The second icon is the user's one, if clicked userbox is displayed)
    const loggedContent = (
        <Fragment>
            {!isFullScreenMenu ?
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    onClick={() => dispatch(toggleMobileMenu())}>
                    <MenuIcon className={classes.icons} />
                </IconButton> : null}
            {title}
            <div>
                <IconButton
                    onClick={() => dispatch(toggleDarkMode())}>
                    <DarkModeIcon className={classes.icons} />
                </IconButton>
                <IconButton
                    onClick={(ev) => userbox === null ?
                        setUserbox(ev.currentTarget) : null}>
                    <AccountCircleIcon className={classes.icons} />
                </IconButton>
            </div>
            
            <UserBox
                anchorEl={userbox} // Read userbox.js to study
                onClose={() => setUserbox(null)}/>
        </Fragment>
    );

    // The div when Linear Loading is not present
    // with the same height of Linear Loading
    const notLinearLoading =
        <div style={{width: '100%', height: '0.25rem'}}></div>;

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar style={{
                display: 'flex',
                flexDirection: 'row',
                // To center the title where not logged in
                justifyContent: logged ? 'space-between' : 'center',
                // flexWrap: 'wrap',
            }}>
                {/* Displays only the title if not logged in, otherwise all: */}
                {logged === true ? loggedContent : title}
            </Toolbar>
            {/* Displays Linear Progress on loading, otherwise not: */}
            {loading ? <LinearProgress /> : notLinearLoading}
        </AppBar>
    );
}

Header.defaultProps = {
    menuWidth: 72,
    disableLeftPadding: false,
};

export default Header;
