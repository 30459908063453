export default function checkPIVA(piva: string) {
    if (piva === '') return false;
    if (!(/^[0-9]{11}$/.test(piva))) {
        return false;
    }

    // Trasformo piva in un array
    const pi = piva.split('');


    // Calcolo della cifra di controllo dell'iva

    // Somma dei numeri in posizione dispari
    // (la prima cifra è considerata in posizione 1)
    let d = 0; // DISPARI
    for (let c = 1; c <= 9; c += 2) { // DISPARI
        d += parseInt(pi[c - 1]);
    }

    // Somma dei doppi dei numeri in posizione pari
    let p = 0; // PARI
    for (let c = 2; c <= 10; c += 2) {
        let s = 2 * (parseInt(pi[c - 1]));
        if (s > 9) s -= 9;
        p += s;
    }

    // Calcolo undicesima cifra (quella di controllo)
    // E confronto con quella inserita
    if ((10 - ((p + d) % 10)) % 10 !== parseInt(pi[11 - 1])) {
        return false;
    }
    return true;
};
