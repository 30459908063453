import type { AddRemData, InputDataType } from "components/reusable/EditBox/EditBoxTypes";

const productsFields : InputDataType = {
    name: {
        name: 'Descrizione',
        required: true,
    },
    catalog: {
        name: 'Listino',
        type: 'select',
        options: [],
    },
    price: {
        name: 'Prezzo',
        required: true,
        validate: (newValue: string) => {
            const v = Number(newValue); // .replace(',', '.');
            return !(Number.isNaN(v)) && v >= 0;
        }
    },
    iva: {
        name: 'Iva',
        required: true,
        options: [
            {key: '0', value: '0'},
            {key: '5', value: '5'},
            {key: '22', value: '22'}
        ]
    },
    discounts: {
        name: 'Sconti',
        type: 'addrem',
        validate: (newValue: AddRemData[]) => {
            const disc = [];
            for (let i = 0; i < newValue.length; i++) {
                const {_id, from, to, percent} = newValue[i]
                disc.push({
                    _id, from: from.value, to: to.value, percent: percent.value,
                })
            }
            disc.forEach((v) => {
                v.from = Number(v.from);
                v.to = Number(v.to);
            });
            disc.sort((a, b) => a.from - b.from);
            disc.push({from: Infinity, to: 0});
            for (let c = 0; c < (disc.length - 1); c++) {
                if (disc[c].to >= disc[c + 1].from) return false;
            }
            return true;
        },
        schema: {
            from: {
                name: 'Da',
                required: true,
                validate: (newValue: string, get: (key: string) => string) => {
                    const from = Number(newValue);
                    const to = Number(get('to'));
                    return !(Number.isNaN(from)) && Number.isInteger(from) &&
                        from >= 0 && from <= to;
                },
                validateAll: true, // validates all the fields in the AddRem Unit, not all the other fields
            },
            to: {
                name: 'a',
                required: true,
                validate: (newValue: string, get: (key: string) => string) => {
                    const to = Number(newValue);
                    const from = Number(get('from'));
                    return !(Number.isNaN(to)) && Number.isInteger(to) && to >= from;
                },
                validateAll: true, // validates all the fields in the AddRem Unit, not all the other fields
            },
            percent: {
                name: '%',
                required: true,
                validate: (newValue: string) => {
                    const v = Number(newValue);
                    return !(Number.isNaN(v)) && v >= 0 && v <= 100;
                },
            },
        }
    },
    notes: {
        name: 'Note',
        multiline: true,
    }
};

export default productsFields;
