import { createSlice } from "@reduxjs/toolkit";

// Types
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Article as ImportedArticle, Catalog, ExtendedOrder, Product } from "components/types";
import type { WritableDraft } from "immer/dist/internal";

export interface PopulatedArticle extends Omit<ImportedArticle, '_id'> {
    index: number,
}
interface Article extends Omit<PopulatedArticle, 'product' | 'index'> {
    product: string,
}
interface CatalogWithProducts extends Catalog {
    products: Product[],
}
interface CatalogWithArticles extends Catalog {
    articles: PopulatedArticle[],
}
interface InputOrderEditState extends Omit<ExtendedOrder, 'articles'> {
    articles: Article[],
    data: CatalogWithProducts[],
}
interface OrderEditState extends Omit<ExtendedOrder, 'articles'> {
    articles: Article[],
    initialArticles: Article[],
    data: CatalogWithArticles[],
}

type Edit = {
    catalogIndex: number,
    productIndex: number,
    newValue?: string,
};


// In italian: "prezzo di cessione"
export const getSalePrice = (article: PopulatedArticle) => {
    const { product, discount = 0 } = article;
    return product.price * (1 - discount / 100);
}
export const getPrice = (article: PopulatedArticle) => {
    return article.number * getSalePrice(article);
}

const getDefaultDiscount = (article: PopulatedArticle) => {
    const { number, product } = article;
    const { discounts } = product;
    for (let i = 0; i < discounts.length; i++) {
        if (discounts[i].from <= number && number <= discounts[i].to)
            return discounts[i].percent;
    }
    return 0;
}

const updateArticles = (newArticle: PopulatedArticle, state: WritableDraft<OrderEditState>) => {
    const i = state.articles.findIndex((art) =>
        art.product === newArticle.product._id);
    if (i < 0) {
        state.articles.push({
            number: newArticle.number,
            product: newArticle.product._id,
            discount: newArticle.discount,
        });
    } else {
        const art = state.articles[i];
        if (newArticle.number <= 0) {
            state.articles.splice(i, 1);
        }
        art.number = newArticle.number;
        art.discount = newArticle.discount;
    }
}

const editReducer = (state: WritableDraft<OrderEditState>, action: PayloadAction<Edit>) => {
    const { catalogIndex, productIndex } = action.payload;
    if (catalogIndex < 0 || productIndex < 0) return;
    if (state.data[catalogIndex]?.articles[productIndex] === undefined) {
        return;
    }
    const article = state.data[catalogIndex].articles[productIndex];
    const newValue = Number(action.payload.newValue);
    state.total -= getPrice(article);

    type EditActions = `orderEdit/${'increaseQuantity' | 'decreaseQuantity' |
        'setQuantity' | 'setDiscount'}`;
    
    switch (action.type as EditActions) {
        case 'orderEdit/increaseQuantity':
            if (article.number >= MAX_QUANTITY) return;
            article.number++;
            article.discount = getDefaultDiscount(article);
            break;
        case 'orderEdit/decreaseQuantity':
            if (article.number <= 0) return;
            article.number--;
            article.discount = getDefaultDiscount(article);
            break;
        case 'orderEdit/setQuantity':
            if (Number.isNaN(newValue)) return;
            if (newValue >= MAX_QUANTITY) return;
            article.number = newValue;
            article.discount = getDefaultDiscount(article);
            break;
        case 'orderEdit/setDiscount':
            if (Number.isNaN(newValue)) return;
            if (newValue <= 0 || newValue >= 100) return;
            const defaultDiscount = getDefaultDiscount(article);
            if (newValue <= defaultDiscount) {
                article.discount = newValue;
            } else {
                article.discount = defaultDiscount;
            }
            break;
        default:
            console.log('Altro caso');
    }
    state.total += getPrice(article);
    updateArticles(article, state);
}


export const initialState : OrderEditState = {
    _id: '',
    n: 0,
    agent: {
        _id: '',
        name: {
            first: '',
            last: '',
        }
    },
    creationDate: '',
    lastModified: {
        date: '',
        user: '',
    },
    customer: {
        _id: '',
        name: '',
    },
    initialArticles: [],
    articles: [],
    data: [],
    paymentMethod: "",
    total: 0,
};

const MAX_QUANTITY = 100000000;

export const orderEditSlice = createSlice({
    name: 'orderEdit',
    initialState,
    reducers: {
        initialise: (state, action: PayloadAction<InputOrderEditState>) => {
            const { _id = '', n, agent, creationDate,
                lastModified, customer,
                data, paymentMethod, total = 0 } = action.payload;
            const initialArticles = action.payload.articles;
            
            const newData : CatalogWithArticles[] = [];
            for (let i = 0; i < data.length; i++) {
                const { products } = data[i];
                const populatedArticles : PopulatedArticle[] = [];
                for (let j = 0; j < products.length; j++) {
                    const k = initialArticles.findIndex((initialArticle) =>
                        initialArticle.product === products[j]._id);
                    const defaultDiscount = getDefaultDiscount({index: 0, number: 0, product: products[j]});
                    let number: number = 0, discount: number = defaultDiscount;
                    if (k >= 0) {
                        const initialDiscount = initialArticles[k].discount;
                        number = initialArticles[k].number;
                        discount = initialDiscount !== undefined ?
                            initialDiscount : defaultDiscount;
                    }
                    populatedArticles.push({
                        index: j,
                        number, discount,
                        product: products[j],
                    });
                }
                newData.push({
                    _id: data[i]._id,
                    name: data[i].name,
                    articles: populatedArticles,
                })
            }

            state._id = _id;
            state.n = n;
            state.agent = agent;
            state.creationDate = creationDate;
            state.lastModified = lastModified;
            state.customer = customer;
            state.paymentMethod = paymentMethod;
            state.total = total;
            state.initialArticles = initialArticles;
            state.articles = initialArticles;
            state.data = newData;

            // const newState : OrderEditState = {
            //     _id, n, agent, creationDate, lastModified,
            //     customer, paymentMethod, total,

            //     articles: initialArticles, initialArticles,
            //     data: newData,
            // }
            // state = newState;
        },
        increaseQuantity: editReducer,
        decreaseQuantity: editReducer,
        setQuantity: editReducer,
        setDiscount: editReducer,
    },
});

export const {
    initialise, increaseQuantity, decreaseQuantity,
    setQuantity, setDiscount,
} = orderEditSlice.actions;

// EXTERNAL HELPERS

export const areThereEdits = (state: OrderEditState) : boolean => {

    if (state.initialArticles.length !== state.articles.length) return true;

    const initialArticles = [...state.initialArticles];
    const articles = [...state.articles];

    const sortAlgorithm = (a: Article, b: Article) => {
        const x = a.product; // .toLowerCase() no need
        const y = b.product; // .toLowerCase() no need
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
    };

    initialArticles.sort(sortAlgorithm);
    articles.sort(sortAlgorithm);

    for (let i = 0; i < articles.length; i++) {
        for (const articleKey in articles[i]) {
            const key = articleKey as keyof Article;
            if (articles[i][key] !== initialArticles[i][key]) {
                return true;
            }
        }
    }

    return false;
}


export default orderEditSlice.reducer;