import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Color } from "@material-ui/lab";

export type NotificationType = {
    severity: Color,
    message: string,
    duration?: number,
};
type State = {
    queue: NotificationType[],
    showing: null | NotificationType,
    open: boolean, // handler for animation
};
const initialState : State = {
    queue: [],
    showing: null,
    open: false,
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        push: (state, action: PayloadAction<NotificationType>) => {
            if (state.showing === null) {
                state.showing = action.payload;
                state.open = true;
            } else {
                state.queue.push(action.payload);
            }
        },
        shift: (state) => {
            const n = state.queue.shift();
            if (n === undefined) {
                state.showing = null;
            } else {
                state.open = true;
                state.showing = n;
            }
        },
        closeNotification: (state) => {
            state.open = false;
        }
    },
});

export const selectNotification = (state: RootState) => state.notifications.showing;
export const selectNotificationIsOpen = (state: RootState) => state.notifications.open;
export const {push, shift, closeNotification} = notificationsSlice.actions;

export default notificationsSlice.reducer;