// IMPORT
// import 'regenerator-runtime/runtime';
import React, { useEffect } from 'react';

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from '../app/hooks';
// Actions
import { enter } from 'features/authActions';
// Selectors
import { selectTab } from '../features/globalState';

// In order to display notifications
import Notification from 'features/Notification';

// Material UI
import { makeStyles, createStyles, useTheme } from '@material-ui/core';

// Components
import Header from './/Header';
import DariMenu from './menus/SideMenu';
import loadTab, { displayMenu } from './loadTab';

// Types
import type { Theme } from '@material-ui/core';


const rootStyles = makeStyles((theme: Theme) => createStyles({ // styles
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        display: 'flex',
    },
    // Necessary for content to be below AppBar:
    appBarHeight: theme.mixins.toolbar,
    main: { // Tab root
        flexGrow: 1, // Grow in all root space
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        transition: 'background .3s ease 0s',
        overflow: 'auto',
    },
}));

function DariSalus() {
    // Opened tab
    const tab = useAppSelector(selectTab);
    // isLogged if tab == 'login' || 'loading'
    const isLogged = !(tab === 'loading' || tab === 'login');

    // Redux Dispatch
    const dispatch = useAppDispatch();

    // After mounting the main component, checking auth:
    // if there's a user logged, redirecting the user into the app.
    useEffect(() => {
        dispatch(enter());
    }, [isLogged]);

    const classes = rootStyles(); // Applying styles
    const theme = useTheme();
    const isDarkModeEnabled = theme.palette.type === 'dark';
    
    return (
        <div className={classes.root}>
            <Header
                // To disable the padding present where the menu is displayed
                disableLeftPadding={!(displayMenu(tab))}
            />
            {/* Visibility of MenuBar when we are not making a new order: */}
            {displayMenu(tab) ? <DariMenu /> : null}
            {/* The tab to display */}
            <main className={classes.main}
                style={{backgroundColor: tab === 'orderEdit' ?
                isDarkModeEnabled ? '#262a42' : '#9ad8ff' : 'inherit'}}>
                {/* Necessary for content to be below AppBar: */}
                <div className={classes.appBarHeight} />
                {loadTab(tab)}
                <Notification />
            </main>
        </div>
    );
}
export default DariSalus;
