// IMPORT
import React, { Fragment, useState } from 'react';

// REDUX
// Hooks
import { useAppDispatch } from 'app/hooks';
// Actions
import { push } from 'features/notifications';

// Material-UI
import { Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Material Icons
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import InfoBox from 'components/reusable/InfoBox';
import Dialog from 'components/reusable/Dialog';
import cF from './customersFields.json';

// Utils
import axios from 'axios';

// Hooks
import useData from 'components/hooks/useData';

// Types
import type { ExtendedCustomer } from 'components/types';


const styles = makeStyles((theme) => createStyles({
    boldText: {
        fontWeight: 'bold',
    },
}));

const initialData : ExtendedCustomer = {
    _id: '', name: '',
    address: {
        _id: '', address: '',
        postCode: '', town: '',
        province: '',
    },
    country: '', phone: '',
    email: '', iva: '',
    iban: '', type: '',
};

type CustomerInfoProps = {
    open: boolean,
    id: string,
    onClose: () => void,
    edit: (id: string) => void,
    newOrder: (id: string) => void,
};


function CustomerInfo(props: CustomerInfoProps) {
    const classes = styles();
    const dispatch = useAppDispatch();
    const {open, onClose, edit, id, newOrder} = props;
    const [wantDeleteDialog, setWantDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const url = id === '' ? '' : `/api/customers/${id}`;
    const [data] = useData<ExtendedCustomer>({url, initialData,
        onFetching: () => setLoading(true),
        onFetched: () => setLoading(false),
    });


    const keys = Object.keys(data);
    const fields = keys.map((k) => {
        if (data[k as keyof ExtendedCustomer] === '') return null;

        switch (k) {
        case '_id':
        case 'country':
        case 'type':
            return null;
        case 'name':
            return (
                <Typography key={k} variant="h4">
                    {data[k]}
                </Typography>
            );
        case 'address':
            if (data.address.address === '') return null;
            return (
                <Fragment key={k}>
                    <Typography variant="h5">
                        {`${data[k].address}, ` +
                        `${data[k].postCode} ${data[k].town}, ` +
                        data[k].province}
                    </Typography>
                    <div style={{height: 16}}></div>
                </Fragment>
            );
        case 'email':
        case 'iva':
        case 'sdiCode':
        case 'iban':
            return (
                <Typography key={k} className={classes.boldText}>
                    {cF[k].name}: {data[k]}
                </Typography>
            );
        default:
            return (
                <Typography key={k}>
                    {cF[k].name}: {data[k as keyof ExtendedCustomer]}
                </Typography>
            );
        }
    });

    const actions = (
        <Fragment>
            <div>
                <Button
                    variant="contained"
                    onClick={() => edit(id)}>
                        Modifica
                </Button>
                <IconButton onClick={() => setWantDeleteDialog(true)}>
                    <DeleteIcon />
                </IconButton>
            </div>
            <Button
                variant="contained"
                onClick={() => newOrder(id)}
                color="primary">
                    Nuovo ordine
            </Button>
        </Fragment>
    );

    const handleDeleteCustomer = () => {
        axios.delete('/api/customers/' + id)
            .then(() => {
                dispatch(push({
                    severity: 'success',
                    message: 'Cliente eliminato con successo!',
                }));
                onClose();
            })
            .catch(() => dispatch(push({
                severity: 'error',
                message: 'Errore: impossibile eliminare questo cliente.',
                duration: 4000,
            })))
            .finally(() => setWantDeleteDialog(false));
    };

    return (
        <InfoBox open={open} actions={actions}
            onClose={onClose} loading={loading}>
            {fields}

            <Dialog open={wantDeleteDialog} title="Attenzione"
                onCancel={() => setWantDeleteDialog(false)}
                onClose={handleDeleteCustomer}>
                    Vuoi davvero eliminare questo cliente?
            </Dialog>
        </InfoBox>
    );
}

export default CustomerInfo;
