// IMPORT
import React from 'react';

// Hooks
import { useAppDispatch, useAppSelector } from 'app/hooks';
// Actions
import { setTab, toggleMobileMenu} from 'features/globalState';
// Selectors
import { selectUser, selectMobileMenu } from 'features/globalState';

// Material-UI
import { Divider, Drawer, Hidden } from '@material-ui/core';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core';

// Hooks
import useMenuButtons from './useMenuButtons';

// Types
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, {width?: number}>
    ((theme) => createStyles({ // styles
    menu: {
        [theme.breakpoints.up('sm')]: {
            // If menu is displayed permanently (not in mobile)
            // Sets the width
            width: (props) => props.width,
            flexShrink: 0,
        },
    },
    menuPaper: {
        width: (props) => props.width,
        backgroundColor: 'rgba(0,0,0,0)',
    },
    // Necessary for menu icons to be below AppBar:
    appBarHeight: theme.mixins.toolbar,
    // Spacing in between buttons:
    iconButtons: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    tooltip: {
        fontSize: '.7rem',
    },
}));


function DariMenu(props: {width?: number}) {
    const classes = useStyles(props);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    // Handling Mobile Menu opening:
    const mobileMenuOpen = useAppSelector(selectMobileMenu);

    const wantUsersButton = (user && user.role === 's') || false;
    const buttonProps = useMenuButtons({wantUsersButton});


    // Buttons becoming React Element with Array.map()
    const buttons = buttonProps.map((button, index) => (
        <div className={classes.iconButtons} key={index}>
            <Tooltip title={button.title} placement="right" enterDelay={300}
                classes={{tooltip: classes.tooltip}}>
                <IconButton style={{backgroundColor: button.bgColor}}
                    onClick={() => dispatch(setTab(button.tab))}>
                    <button.icon fontSize="large" style={{color: theme.palette.text.primary}}/>
                </IconButton>
            </Tooltip>
        </div>
    ));

    // The  Menu Content
    const menu = (
        <div style={{height: '100%'}}>
            {/* Necessary for content to be below AppBar: */}
            <div className={classes.appBarHeight} />

            <Divider />

            {/* Icon Buttons: */}
            <Grid container alignItems="center"
                direction="column" justifyContent="center"
                style={{height: `calc(80% - 65px)`}}>
                {buttons}
            </Grid>
        </div>
    );

    return (
        <nav className={classes.menu}>
            {/* Displays 'sm' size and hide up: */}
            <Hidden smUp implementation="css">
                <Drawer // This is how the 'menu' is called at Material UI
                    // Drawer Documentation:
                    // https://material-ui.com/components/drawers/
                    // https://material-ui.com/api/drawer/

                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileMenuOpen}
                    onClose={() => dispatch(toggleMobileMenu())}
                    // Passing css to the Paper component inside the Drawer:
                    classes={{
                        paper: classes.menuPaper,
                    }}
                    ModalProps={{
                        // Better open performance on mobile:
                        keepMounted: true,
                    }}
                >
                    {menu}
                </Drawer>
            </Hidden>
            {/* Displays 'xs' size and hide down: */}
            <Hidden xsDown implementation="css">
                <Drawer
                    // Passing css to the Paper component inside the Drawer:
                    classes={{
                        paper: classes.menuPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {menu}
                </Drawer>
            </Hidden>
        </nav>
    );
}

DariMenu.defaultProps = {
    width: 72,
};

export default DariMenu;
