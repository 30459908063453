import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// TYPES
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { GlobalStateType, User } from "./globalStateTypes";
import { WritableDraft } from "immer/dist/internal";

// To perform requests
import axios from 'axios';
// To offline-logout
import Cookies from 'js-cookie';

export const enter = createAsyncThunk(
    'globalState/enter',
    async () => {
        return (await axios.get('/api/getCurrentUser',
            {validateStatus: (stat) => stat === 200})).data.result;
    }
);
export const logout = createAsyncThunk(
    'globalState/logout',
    async () => axios.post('/api/logout')
);

export const extraReducers = (builder: ActionReducerMapBuilder<GlobalStateType>) => {
    // enter
    builder.addCase(enter.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(enter.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        state.tab = 'fullScreenMenu';
        state.loading = false;
    });
    builder.addCase(enter.rejected, (state) => {
        state.tab = 'login';
        state.loading = false;
    });

    // logout
    const finallyLogout = (state: WritableDraft<GlobalStateType>) => {
        state.user = null;
        state.tab = 'login';
        state.loading = false;
    }
    builder.addCase(logout.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(logout.fulfilled, finallyLogout);
    builder.addCase(logout.rejected, (state) => {
        // Offline Logout
        Cookies.remove('darisaluSS', {path: ''});
        finallyLogout(state);
    });
};