// IMPORT
import React, { useState } from 'react';

// REDUX
// Hooks
import { useAppDispatch } from 'app/hooks';
// Actions
import { push } from 'features/notifications';

// Material-UI
import { Typography, Button } from '@material-ui/core';
import { TableBody as Tb } from '@material-ui/core';
import { TableCell as Td, TableRow as Tr } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

// Components
import InfoBox from 'components/reusable/InfoBox';
import Table from 'components/reusable/Table';
import RoleHide from 'components/reusable/RoleHide';
import Dialog from 'components/reusable/Dialog';

// Utils
import axios from 'axios';

// Hooks
import useData from 'components/hooks/useData';

// Types
import type { ExtendedProduct } from 'components/types';

const styles = makeStyles((theme) => createStyles({
    boldText: {
        fontWeight: 500,
    },
    discountsBox: {
        margin: 0,
        padding: 0,
        paddingLeft: theme.spacing(2),
    },
}));

const initialData : ExtendedProduct = {
    _id: '',
    catalog: '',
    name: '',
    price: 0,
    iva: '0',
    discounts: []
};

type ProductInfoProps = {
    open: boolean,
    id: string,
    onClose: () => void,
    edit: (id: string) => void,
};

function ProductInfo(props: ProductInfoProps) {
    const classes = styles();
    const {open, onClose, edit, id} = props;

    const dispatch = useAppDispatch();
    const [wantDeleteDialog, setWantDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const url = id === '' ? '' : `/api/products/${id}`;
    const [data] = useData<ExtendedProduct>({url, initialData,
        onFetching: () => setLoading(true),
        onFetched: () => setLoading(false),
    });

    const opts = {minimumFractionDigits: 2, maximumFractionDigits: 2,
        style: 'currency', currency: 'EUR'};
    
    const price = Number(data.price).toLocaleString(undefined, opts);
    const discounts = data.discounts.map((v, i) => (
        <li key={i}>{v.from} - {v.to} pz: {v.percent}%</li>
    ));
    const discountBox = data.discounts.length > 0 ? (
        <ul className={classes.discountsBox}>
            {discounts}
        </ul>
    ) : 'Nessuno sconto applicabile.';

    const actions = (
        <RoleHide>
            <Button
                variant="contained"
                onClick={() => setWantDeleteDialog(true)}>
                    Elimina</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => edit(id)}>
                        Modifica
            </Button>
        </RoleHide>
    );

    const handleDeleteProduct = () => {
        axios.delete('/api/products/' + id)
        .then(() => {
            dispatch(push({
                severity: 'success',
                message: 'Prodotto eliminato con successo!',
            }));
            onClose();
        })
        .catch(() => dispatch(push({
            severity: 'error',
            message: 'Errore: impossibile eliminare questo prodotto.',
            duration: 4000,
        })))
        .finally(() => setWantDeleteDialog(false));
    };

    return (
        <InfoBox open={open} actions={actions}
            onClose={onClose} loading={loading}>
            {/* To display only if admin:*/}
            <RoleHide><div style={{height: 16}}></div></RoleHide>
            <Table elevation={4} disableMargin>
                <Tb>
                    <Tr>
                        <Td colSpan={2} className={classes.boldText}>
                            <Typography variant="h6"
                                style={{textAlign: 'center'}}>
                                {data.name}
                            </Typography>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>Prezzo</Td>
                        <Td className={classes.boldText}>{price}</Td>
                    </Tr>
                    <Tr>
                        <Td>Iva</Td>
                        <Td>{data.iva}%</Td>
                    </Tr>
                    <Tr>
                        <Td>
                            Sconti
                        </Td>
                        <Td>
                            {discountBox}
                        </Td>
                    </Tr>
                    {data.notes ? <Tr>
                        <Td>
                            Note
                        </Td>
                        <Td>
                            {data.notes}
                        </Td>
                    </Tr> : null}
                </Tb>
            </Table>

            <Dialog open={wantDeleteDialog} title="Attenzione"
                onCancel={() => setWantDeleteDialog(false)}
                onClose={handleDeleteProduct}>
                    Vuoi davvero eliminare questo prodotto?
            </Dialog>
        </InfoBox>
    );
}

export default ProductInfo;
