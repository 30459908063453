// IMPORT
import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core';

// Components
import { SwitchField } from 'components/reusable/EditBox/EditBoxFields';

// Types
import type { PayloadAction } from '@reduxjs/toolkit';
import type { JSXFieldProps } from 'components/reusable/EditBox/EditBoxFields';
import type { Edit } from 'components/reusable/EditBox/EditBoxTypes';

const useStyles = makeStyles((theme) => ({
    textfields: {
        width: '24ch',
        margin: `${theme.spacing(0.625)}px ${theme.spacing(1)}px 0`,
    },
    fields: {
        display: 'flex',
        alignItems: 'end',
    },
}));

function CatalogNameField(props: JSXFieldProps) {
    const classes = useStyles();
    const {data, shaking} = props;

    const onEdit = (action: PayloadAction<Edit<string>>) => {
        const { key, newValue } = action.payload;
        const { _id } = data;
        if (_id)
            props.onChange(_id, key, newValue);
    };

    return (
        <div className={classes.fields}>
            {/* <TextField
                size="small"
                className={clsx(classes.textfields,
                    Boolean(shakeClass) && error.name && shakeClass)}
                margin="dense"
                error={data.}
                value={data.name}
                disabled={disabled}
                onChange={(e) =>
                    onChange(data._id, 'name', e.target.value)}>
            </TextField> */}
            <SwitchField 
                dataKey='name'
                dispatch={onEdit}
                data={data.name}
                shake={shaking}

                margin="dense"
                className={classes.textfields}
            />
        </div>
    );
};

export default CatalogNameField;
