import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles((theme) => createStyles({ // styles
    container: {
        display: 'flex',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paperContainer: {
        padding: `0 ${theme.spacing(4)}px`,
        height:
            `calc(100% - ${theme.spacing(4)}px - ${theme.spacing(14.5)}px)`,
        [theme.breakpoints.up('sm')]: {
            padding: `0 ${theme.spacing(6)}px`,
            height:
            `calc(100% - ${theme.spacing(6)}px - ${theme.spacing(14.5)}px)`,
        },
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        overflow: 'auto',
    },
    actionsBox: {
        alignSelf: 'flex-end',
        padding: theme.spacing(3),
    },
    buttons: {
        margin: theme.spacing(1),
    },
    fields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: `${theme.spacing(.25)}px ${theme.spacing(1)}px`,
        flexGrow: 1,
        width: 392,
    },
    fieldName: {

    },
}));
