import React from 'react';

import Login from './Login';
import FullScreenMenu from './menus/FullScreenMenu';
import Customers from './customers';
import Orders from './orders';
import OrderEdit from './orders/OrderEdit/OrderEdit';
import Products from './products';
import Users from './users';

import type { Tab } from '../features/globalStateTypes';

const tabs = {
    loading: () => <div></div>,
    fullScreenMenu: () => <FullScreenMenu />,
    login: () => <Login />,
    customers: () => <Customers />,
    orders: () => <Orders />,
    orderEdit: () => <OrderEdit />,
    products: () => <Products />,
    users: () => <Users />,
}

const loadTab = (tab: Tab) => {
    return tabs[tab]();
}

export const displayMenu = (tab: Tab) => {
    switch (tab) {
        case 'loading':
        case 'login':
        case 'fullScreenMenu':
        case 'orderEdit':
            return false;
        default:
            return true;
        }
}

export default loadTab;