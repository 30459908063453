// IMPORT
import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { JSXFieldType, SwitchField } from './EditBoxFields';

// LOCAL STATE
// Reducer + initial data
import editBoxDataReducer, { initialData } from './EditBoxReducer';
// Actions
import { initialise, populate, clear } from './EditBoxReducer';
// Helpers
import { areThereEdits, isAllValid, sanitizeData  } from './EditBoxReducer';

// REDUX
// Hooks
import { useAppDispatch } from 'app/hooks';
// Actions
import { push } from 'features/notifications';

// Material-UI
import { Button, Typography } from '@material-ui/core';
import useEditBoxStyles from './useEditBoxStyles';

// Components
import InfoBox from 'components/reusable/InfoBox';
import Dialog from 'components/reusable/Dialog';

// Utils
import axios from 'axios';
import clsx from 'clsx';

// Types
import type { InputDataType } from './EditBoxTypes';


type EditBoxProps = {
    open: boolean,
    id: '' | '--new' | string,
    title: string,
    template: InputDataType,
    getUrl: string,
    postUrl: string,
    disableCancel?: boolean,
    onClose: () => void,
    onCancel?: (id: string) => void,

    // Styles
    classes?: {container?: string, fieldBox?: string, field?: string, fieldName?: string},
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    fieldsProps?: {[key: string]: {JSXField?: JSXFieldType}},
};

function EditBox(props: EditBoxProps) {
    const classes = useEditBoxStyles({...props, classes: undefined});
    const {container, paper, paperContainer, actionsBox} = classes;
    const inhClasses = {container: clsx(container, props.classes?.container), paper, paperContainer, actionsBox};

    const { open, id, title, template, fieldsProps,
        getUrl, postUrl, onClose, onCancel, maxWidth} = props;

    const appDispatch = useAppDispatch();

    // Local State
    const [loading, setLoading] = useState(false);
    const [discardDialog, setDiscardDialog] = useState(false);
    const [shaking, setShaking] = useState(false);
    
    const [data, dispatch] = useReducer(editBoxDataReducer, initialData);


    const viewCommunicationError = () => appDispatch(push({
        severity: 'error',
        duration: 4000,
        message: 'Errore di comunicazione col server.'
    }));

    const shake = () => {
        setShaking(true);
        setTimeout(() => setShaking(false), 500);
    };

    // Events
    const handlePopulate = () => dispatch(populate(template));
    useEffect(handlePopulate, []);

    const handleInitialise = () => {
        if (id === '') return;
        axios.get(getUrl.replace(':id', id))
            .then(({data}) => dispatch(initialise(data.result)))
            .catch((err) => viewCommunicationError());
    };
    useEffect(handleInitialise, [id]);

    const handleSubmit = () => {
        if (!(areThereEdits(data))) {
            appDispatch(push({
                severity: 'info',
                message: 'Nessuna modifica effettuata.',
            }))
            return handleClose(true);
        }
        if (!(isAllValid(data))) {
            shake();
            return appDispatch(push({
                severity: 'error',
                duration: 4000,
                message: 'Errore. Ricontrolla i dati inseriti.',
            }));
        }
        
        // Preparing for submit:
        let url = postUrl;
        url = url.replace(':action', id === '--new' ? 'insert' : 'edit');
        url = url.replace(':id', id === '--new' ? '' : id);
        
        // Submit
        setLoading(true);
        axios.post(url, sanitizeData(data, true))
            .then(() => {
                handleClose(true, true);
                appDispatch(push({
                    severity: 'success',
                    message: `${title} salvato!`,
                }));
            })
            .catch(() => viewCommunicationError())
            .finally(() => setLoading(false));
    };
    const handleClose = (backToThingInfo = false, doWhileEditing = false) => {
        if (!loading) {
            const exit = backToThingInfo && onCancel ? onCancel : onClose;

            // If there no edits, just closing:
            if (!(areThereEdits(data))) {
                if (props.disableCancel) return;
                return exit(id);
            }
            if (!doWhileEditing) {
                if (props.disableCancel) return;
                return setDiscardDialog(true);
            }
            return exit(id);
        }
    };

    const handleCloseDialog = (wantDiscard?: boolean) => {
        setDiscardDialog(false);
        if (wantDiscard) {
            handleClose(true, true);
        }
    };

    // Fields
    const keys = Object.keys(data);
    const fields = keys.map((key) => {
        return (
            <div key={key}
                className={clsx(classes.fields, props.classes?.fieldBox)}
            >
                <div
                className={clsx(classes.fieldName, props.classes?.fieldName)}
                    // style={ovStyles.fieldName}
                >
                    <Typography>{data[key].name}</Typography>
                </div>
                <SwitchField
                    data={data[key]}
                    dataKey={key}
                    dispatch={dispatch}
                    shake={shaking}
                    className={props.classes?.field}
                    JSXField={fieldsProps?.[key]?.JSXField}
                ></SwitchField>
            </div>
        )
    })

    const actions = <Fragment>
        {props.disableCancel ? null :
            <Button
                variant="contained"
                onClick={() => handleClose()}
                className={classes.buttons}
                disabled={loading}>
                    Annulla
            </Button>
        }
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.buttons}
            disabled={loading}>
                Salva
        </Button>
    </Fragment>;

    
    return (
        <InfoBox open={open} actions={actions} loading={loading}
            onClose={handleClose} maxWidth={maxWidth}
            onExited={() => dispatch(clear())}
            buttonsAlign="flex-end" classes={inhClasses}
        >
            <Typography variant="h4">
                {(id === '--new' ? 'Aggiungi' : 'Modifica') + ' ' + title}
            </Typography>
            <div className={classes.fieldsContainer}>
                {fields}
            </div>

            <Dialog
                open={discardDialog}
                title="Vuoi annullare le modifiche?"
                onClose={() => handleCloseDialog(true)}
                onCancel={() => handleCloseDialog()}
            >
                Perderai tutto ciò che hai modificato finora.
            </Dialog>
        </InfoBox>
    );
}


EditBox.defaultProps = {
    title: 'Edit Box',
    height: '85%',
};

export default EditBox;