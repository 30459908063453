// IMPORT
import React, { Fragment, useState } from 'react';

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from 'app/hooks';
// Actions
import { setLoading } from 'features/globalState';
// Selectors
import { selectUser } from 'features/globalState';

// Material-UI
import { Container } from '@material-ui/core';
import { TableHead as Th, TableBody as Tb } from '@material-ui/core';
import { TableCell as Td, TableRow as Tr } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';


// Components
import TabHead from 'components/reusable/TabHead';
import ScrollableTabs from 'components/reusable//ScrollableTabs';
import Table from 'components/reusable//Table';
import ProductInfo from './ProductInfo';
import ProductEdit from 'components/reusable/EditBox/EditBox';
import CatalogSettings from 'components/reusable/EditBox/EditBox';
import DiscountField from './DiscountField';
import CatalogNameField from './CatalogNameField';
import productFields from './productsFields.json';

// Hooks
import useData from '../hooks/useData';
import useFilteredData from '../hooks/useFilteredData';

// Types
import type { Product, Catalog, Discount } from 'components/types';

const productsStyles = makeStyles((theme) => createStyles({
        root: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            height: '100%',
            overflow: 'auto',
        },
        cell: {
            fontSize: '1rem',
            padding: theme.spacing(2),
        },
        discountCell: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        ivaCell: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        tableRowItems: {
            '&:hover': {
                backgroundColor: theme.palette.action.focus,
            },
            'textTransform': 'uppercase',
            'cursor': 'pointer',
            'height': theme.spacing(8),
        },
        productEdit_container: {height: '65%'},
        productEdit_fieldBox: {flexGrow: 1},
        productEdit_fieldName: {width: 120},
        productEdit_field: {flexGrow: 1},
        catalogsSettings_container: {overflow: 'hidden'},
}));

function getMaxDiscountIndex(discounts: Discount[]) {
    let percent = -1;
    let index = -1;
    for (let j = 0; j < discounts.length; j++) {
        if (discounts[j].percent > percent) {
            percent = discounts[j].percent;
            index = j;
        }
    }
    return index;
}

function Products() {
    const classes = productsStyles();
    const {cell, discountCell, ivaCell} = classes;
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);
    const role = user !== null ? user.role : 'u';

    const [catalogIndex, setCatalogIndex] = useState(0);
    const [productInfo, setProductInfo] = useState('');
    const [productEdit, setProductEdit] = useState('');
    const [searchField, setSearchField] = useState('');
    const [catSettings, setCatSettings] = useState(false);


    const filter = ({name}: Product, search: string) => 
        name.toLowerCase().includes(search.toLowerCase());

    const [catalogs, fetchCatalogs] = useData<Catalog[]>({url: '/api/products/catalogs', initialData: [],
        onFetching: () => dispatch(setLoading(true)),
        onFetched: () => dispatch(setLoading(false)),
    });
    const currentCatalog = catalogs[catalogIndex];
    const [products, fetchProducts] = useData<Product[]>({url: `/api/products?catalog=${currentCatalog ? currentCatalog._id : ''}`, initialData: [],
        onFetching: () => dispatch(setLoading(true)),
        onFetched: () => dispatch(setLoading(false)),
    });
    const filteredProducts = useFilteredData<Product>({data: products, search: searchField, filter});
    const fetchData = () => {
        fetchCatalogs();
        fetchProducts();
    }
    
    // useEffect(() => fetchData(), [searchField]);

    const jsxData = filteredProducts.map((v) => {
        let discount = '-';
        if (v.discounts.length > 0) {
            const d = v.discounts[getMaxDiscountIndex(v.discounts)];
            discount = `${d.from} - ${d.to} pz: ${d.percent}%`;
        }

        const opts = {minimumFractionDigits: 2, maximumFractionDigits: 2,
            style: 'currency', currency: 'EUR'};
        const price = Number(v.price).toLocaleString(undefined, opts);
        return (
            <Tr className={classes.tableRowItems} key={v._id}
                onClick={() => setProductInfo(v._id)}>
                <Td className={cell}>
                    {v.name}
                </Td>
                <Td className={cell + ' ' + ivaCell}>
                    {v.iva}%
                </Td>
                <Td className={cell + ' ' + discountCell}>
                    {discount}
                </Td>
                <Td className={cell}>
                    {price}
                </Td>
            </Tr>
        );
    });

    const table = (
        <Table>
            <Th>
                <Tr>
                    <Td className={cell}>Descrizione</Td>
                    <Td className={cell + ' ' + ivaCell}>
                        Iva
                    </Td>
                    <Td className={cell + ' ' + discountCell}>
                        Migliore Sconto
                    </Td>
                    <Td className={cell}>Prezzo</Td>
                </Tr>
            </Th>
            <Tb>
                {jsxData}
            </Tb>
        </Table>
    );

    const productsView = (
        <Fragment>
            <TabHead name="Prodotti"
                searchbarState={[searchField, setSearchField]}
                buttonText="Nuovo Prodotto"
                onButtonClick={() => setProductEdit('--new')}
                buttonVisibility={role === 'a' || role === 's'}
            />

            <ScrollableTabs
                tabIndex={catalogIndex}
                namesList={catalogs}
                setTab={setCatalogIndex}
                openSettings={() => setCatSettings(true)}
            >
                {filteredProducts.length > 0 ? table : null}
                <ProductInfo
                    open={Boolean(productInfo)}
                    id={productInfo}
                    edit={(id) => {
                        setProductInfo('');
                        setProductEdit(id);
                    }}
                    onClose={() => {
                        setProductInfo('');
                        fetchData();
                    }}/>
                <ProductEdit id={productEdit}
                    open={Boolean(productEdit)}
                    maxWidth="sm"
                    classes={{
                        fieldName: classes.productEdit_fieldName,
                        field: classes.productEdit_field,
                        fieldBox: classes.productEdit_fieldBox,
                    }}
                    fieldsProps={{discounts: {
                        JSXField: DiscountField,
                    }}}
                    title="Prodotto"
                    template={productFields}
                    getUrl="/api/products/:id?remoteOptions"
                    // getEmptyUrl="/products/empty?remoteOptions"
                    postUrl="/api/products/:action/:id"
                    onClose={() => {
                        setProductEdit('');
                        fetchData();
                    }}
                    onCancel={(id) => {
                        setProductEdit('');
                        // To avoid showing "Product Info"
                        // when action "adding new product" is canceled
                        if (id !== '--new') {
                            setProductInfo(id);
                        }

                        fetchData();
                    }}/>
                <CatalogSettings
                    id={catSettings ? 'catalogs' : ''}
                    open={catSettings}
                    maxWidth="xs"
                    classes={{
                        container: classes.catalogsSettings_container,
                    }}
                    height="65%"
                    title="Cataloghi"
                    template={{
                        cats: {
                            name: '',
                            type: 'addrem',
                            schema: {
                                name: {
                                    name: '',
                                    required: true,
                                },
                            },
                        }
                    }}
                    fieldsProps={{cats: {
                        JSXField: CatalogNameField,
                    }}}
                    getUrl="/api/products/catalogs?incapsulate"
                    postUrl="/api/products/catalogs/:action"
                    onClose={() => {
                        setCatSettings(false);
                        fetchCatalogs();
                    }}
                />
            </ScrollableTabs>
        </Fragment>
    );
    return (
        <div style={{height: 'calc(100% - 64px)'}}>
            <Container maxWidth="lg" disableGutters className={classes.root}>
                {productsView}
            </Container>
        </div>
    );
}

export default Products;
