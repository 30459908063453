import React, { useEffect } from "react";

import DariSalus from "components/DariSalus";

// Material-UI
import { createTheme, ThemeProvider, useMediaQuery } from '@material-ui/core';

// REDUX
// Hooks
import { useAppDispatch, useAppSelector } from './app/hooks';
// Actions
import { setDarkMode } from "features/darkMode";
// Selectors
import { selectDarkMode } from "features/darkMode";

function App() {
    const isDarkModeSystemEnabled = useMediaQuery('(prefers-color-scheme: dark)');
    const dispatch = useAppDispatch();
    const isDarkModeEnabled = useAppSelector(selectDarkMode);

    useEffect(() => {
        dispatch(setDarkMode(isDarkModeSystemEnabled));
    }, [dispatch, isDarkModeSystemEnabled])

    const theme = createTheme({
        palette: {
            type: isDarkModeEnabled ? 'dark' : 'light',
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <DariSalus />
        </ThemeProvider>
    );
}

export default App;