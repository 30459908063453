// IMPORT
import { useMemo } from "react";

type useFilteredDataProps<T> = {
    data: T[],
    search: string,
    filter: (obj: T, search: string) => boolean,
}

function useFilteredData<T>({data, search, filter}: useFilteredDataProps<T>) {
    const filteredData = useMemo(() => {
        const filtData : T[] = [];
        if (search === '') return data;
        for (let i = 0; i < data.length; i++) {
            if (filter(data[i], search)) {
                filtData.push(data[i]);
            }
        }
        return filtData;
    }, [data, search]);

    return filteredData;
}

export default useFilteredData;