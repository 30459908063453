
/**
 * Get Date in format dd/mm/yyyy
 * @param date
 * @param strict If true, days and months with one char, will have a 0 before
 * @return Date in format dd/mm/yyyy
 */
export function ddmmyyyy(date: Date, strict: boolean) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() is zero-based

    const dd = strict && day <= 9 ? '0' + day : day;
    const mm = strict && month <= 9 ? '0' + month : month;

    const yyyy = date.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
}

/**
 * Get Time in format hh:mm
 * @param date
 * @param strict If true, hours with one char, will have a 0 before
 * @return Time in format hh:mm
 */
export function hhmm(date: Date, strict: boolean) {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const hh = strict && hours <= 9 ? '0' + hours : hours;
    const mm = minutes <= 9 ? '0' + minutes : minutes;

    return `${hh}:${mm}`;
}
